﻿@import '_variables';
/* these need to be here for intellisense and go-to-definition to work */
/// <reference path="_variables.scss" />
/// <reference path="_mixins.scss" />
/// <reference path="_placeholders.scss" />
/// <reference path="_grids.scss" />
$bodyBackgroundGradientHeight: 600px;
/****************************************************************************************************/
/* #region Document */
/****************************************************************************************************/

::selection {
    background: $selectionColor;
    color: white; 
}

@include placeholder {
    color: #fff;
    text-transform: none;
}
 
* { 
    box-sizing: border-box;
}

:root {
    --scrollbar-width: 16px;
    --header-height: $headerHeight;
    --card-width: #{$cardWidth};
    --card-gap: #{$cardGap};
    color-scheme: normal !important; // disable dark mode to prevent issues (until addressed)
    /* For Images */
    --image-width: 0;
    --image-height: 0;
    --image-aspectratio: 1.5;
}

html {
    min-height: auto;
    font-style: normal;
    font-family: $primaryFont;
    font-weight: $fontWeightNormal;
    line-height: $lineHeight;
    @include fluid-type($L_Min, $XL_Min, 16px, 16px); // base font size ranges from 16-20px based on screen width
    scroll-behavior: smooth; //use to add smooth scrolling to jump menus with anchors
    scroll-padding-top: 200px;
}

body {
    /*min-height: 100%;*/
    min-height: 100vh;
    /* overflow-x: hidden; // stops mobile menu from adding scrollbars
    overflow-y: auto;*/
    color: $bodyFontColour;
    font-style: normal;
    font-family: $primaryFont;
    font-weight: $fontWeightNormal;
    background-color: white;
    //background-attachment: fixed;
    background: linear-gradient(to bottom, rgba(darken($primaryColour, 10%), 1) 0%, rgba($secondaryColour, 1) $bodyBackgroundGradientHeight);
}

/* Custom Scrollbar Styles (only works in the latest browsers) */
/*::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba($greyXXXLight,0.5);
    background-color: #F5F5F5;
}

::-webkit-scrollbar {
    width: 10px;
    background-color: $greyXXXLight;
}

::-webkit-scrollbar-thumb {
    background-color: $greyXXLight;
    border: 2px solid $greyXXLight;
}*/

/* #endregion */
/****************************************************************************************************/

/****************************************************************************************************/
/* #region Focus Styles */
/****************************************************************************************************/

* {
    outline-offset: $outline_offset;
}

*:focus {
    /* simple focus styles (for older browsers without support for :focus-visible */
    outline: $outline;
}

*:focus:not(:focus-visible) {
    /* undo all the above focused button styles */
    outline: none;
}

*:focus-visible {
    /* focus styles for modern browsers */
    outline: $outline;
}

/* #endregion */
/****************************************************************************************************/

/****************************************************************************************************/
/* #region Text-level Semantics */
/****************************************************************************************************/

p {
    margin: 0 0 1rem 0;
    padding: 0;
    font-size: 1.1rem;
    font-style: normal;
    line-height: $lineHeight;
}

strong,
b {
    /*font-weight: $fontWeightSemiBold;*/
    font-weight: $fontWeightMedium;
    font-style: normal;
}

em,
i {
    font-style: italic;
}

sup {
    vertical-align: super;
    font-size: 40% !important;
    font-style: normal;
}

sub {
    vertical-align: sub;
    font-size: 50%;
    font-style: normal;
}

blockquote {
    width: 100%;
    max-width: 100%;
    margin: 2rem auto;
    position: relative;
    border: none;
    background-color: $secondaryColour;
    font-style: italic;
    padding: 4rem 5rem 5rem;
    box-shadow: $boxShadow;
    color: #fff;
    font-size: 1.8rem;
    font-family: $secondaryFont;
    text-align: center;

    &:after {
        content: "";
        width: 4rem;
        height: 6px;
        background-color: #00d6e6;
        position: absolute;
        left: 50%;
        bottom: 2.5rem;
        transform: translateX(-50%);
    }

    p {
        color: inherit;
        font-style: normal;
        font-size: inherit;
    }

    > :last-child {
        margin-bottom: 0;
    }

    cite {
        font-style: italic;
        font-family: $secondaryFont;
    }

    footer {
    }
}

.personalQuote {
    font-size: 2.5rem !important;
    margin-bottom: 0 !important;
    padding: 1rem;
    border-top: solid 1px $tertiaryColour;
    text-align: center;
}

a {
    color: $greyDark;
    transition: $transition;
    text-decoration: none;
}

a:link {}

a:visited {}

a:hover {
    color: $tertiaryColour;
    text-decoration: underline;
}

a:active {
    color: $tertiaryColour;
    text-decoration: none;
}

hr {
    border: none;
    height: 1px;
    margin: 2rem 0;
    background-color: $greyXXLight;
}

li {
    list-style: none;
    font-size: 1.1rem;
    font-style: normal;
}

ul,
ol {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        list-style: none;
    }

    ul,
    ol {}
}

/* #endregion */
/****************************************************************************************************/

/****************************************************************************************************/
/* #region Headings */
/****************************************************************************************************/

span {
    &.seperator {
        padding: 0 7px;
        color: $greyLight;
    }
}
@media not print {
    /* Headings */
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h {
        @include headingStyles;
        color: $primaryColour;
        margin: 2.4rem 0 1.38rem;
        padding: 0;
        display: block;

        &.centered {
            text-align: center;
        }
    }

    // remove height from consecutive headings
    :is(h1, h2, h3, h4, h5, h6, .h) {
        + :is(h1, h2, h3, h4, h5, h6, .h) {
            margin-top: 0;
        }
    }



    // relative heading sizes generated using https://type-scale.com/ (Major Second)
    h1 {
        color: #fff;
        font-size: 5.8331rem;
        position: relative;
        margin-top: 1rem;
    }

    h2 {
        font-size: 3.4162rem;
        position: relative;
        margin-bottom: 3rem;

        &:after {
            content: '';
            position: absolute;
            height: 6px;
            width: 0;
            background-color: $tertiaryColour;
            left: 0;
            bottom: -1rem;
            transition: .2s ease-in .4s;
        }

        span {
            color: $tertiaryColour;
        }
    }
}
    h3 {
        font-size: 1.824rem;
    }

    h4 {
        font-size: 1.366rem;
    }

    h5 {
        font-size: 1.125rem;
    }

    h6 {
        font-size: 1rem;
    }


    /* fake headings (turn any element into a heading, basically). These need to match the above. */
    .h1 {
        color: $primaryColour !important;
        font-size: 2.488rem !important;
    }

    .h2 {
        font-size: 1.602rem !important;
    }

    .h3 {
        font-size: 1.424rem !important;
    }


    .h4 {
        font-size: 1.266rem !important;
    }

    .h5 {
        font-size: 1.125rem !important;
    }

    .h6 {
        font-size: 1rem !important;
    }


    /* #endregion */
    /****************************************************************************************************/

    /****************************************************************************************************/
    /*#region Page Title + Section Headings + Branded Headings */
    /****************************************************************************************************/

    /* Page Title (Sometimes in topper sometimes in header or body) */
    // psudeo used for detail pages (displays the parent page title)
    span.pseudoH1 {
        @extend %brandedHeading;
        margin: 1rem 0 0.35rem 0;
        color: white;
        font-size: 4rem;
        font-family: $secondaryFont;
        font-weight: $fontWeightBold;
        line-height: 1;
    }

    .branded {
        @extend %brandedHeading;
    }

    /* #endregion */
    /****************************************************************************************************/

    /****************************************************************************************************/
    /* #region Buttons */
    /****************************************************************************************************/
    .button,
    button {
        @include button;
        /* primary colour */
        &.primary {
            background-color: transparent;
            color: $primaryColour;
            border-color: white;

            &:hover,
            &:focus {
                color: white;
                background-color: darken($primaryColour, 5%);
            }

            &:active {
                color: white;
                background-color: darken($primaryColour, 10%);
            }
        }
        /* secondary colour */
        &.secondary {
            background-color: $secondaryColour;
            color: white;
            border-color: $secondaryColour;

            &:hover,
            &:focus {
                color: white;
                background-color: darken($secondaryColour, 5%);
            }

            &:active {
                color: white;
                background-color: darken($secondaryColour, 10%);
            }
        }
        /* tertiary colour */
        &.tertiary {
            background-color: $tertiaryColour;
            color: white;
            border-color: $tertiaryColour;

            &:hover,
            &:focus {
                color: white;
                background-color: darken($tertiaryColour, 5%);
            }

            &:active {
                color: white;
                background-color: darken($tertiaryColour, 10%);
            }
        }
        /* reverse */
        &.reverse {
            @include button("reverse", false);
        }
        /* outline */
        &.outline {
            @include button("outline", true);
        }
        /* Arrow Icon */
        &.arrow {
            @include button("arrow", true);
        }
        /* large */
        &.large {
            @include button("large", true);
        }
    }

    .dark:not(.notDark) .button {
        @include button("dark", true);
        /* Arrow Icon */
        &.arrow {
            &:after {
                content: ' ';
                background-image: url(/CFImages/Icons/arrow-right-white.svg);
            }
        }
    }

.customButtonWrapper {
    margin-top: -3.9rem; //used only when we're adding a manual button
    position: relative;
    z-index: 1;
}
    /* #endregion */
    /****************************************************************************************************/

    /****************************************************************************************************/
    /* #region Hyperlinks + Special Links */
    /****************************************************************************************************/

    /* Special Links */
    a[href^="mailto"] {
        @extend %forceWordBreak;
    }

    /* Telephone Links */
    a[href^="tel"],
    a[href^="Tel"] {
        &:hover {
        }
    }

    /* Non-link elements with onclick functionality */
    .clickable {
        cursor: pointer;
    }

    /* add text-decoration underline */
    a.underline {
        text-decoration: underline;
    }

    /* add text-decoration underline */
    a.underlineHoverOnly {
        text-decoration: none;

        @include focusAndHover {
            text-decoration: underline;
        }
    }

    /* no underline */
    a.noUnderline {
        text-decoration: none;
    }

    /* #endregion */
    /****************************************************************************************************/

    /****************************************************************************************************/
    /* #region Utility Classes - Visibility */
    /****************************************************************************************************/

    .sr-only {
        // screen-readers only (hide visually only)
        @include sr-only;
    }

    .hidden {
        visibility: hidden !important;
        position: absolute !important;
        left: -9999px;
    }

    .forcedHide {
        display: none !important;
    }

    .hideOnDesktop {
        display: none !important;
    }

    .hideOnMobile {
        display: inline-block;
    }

    .printOnly {
        display: none;
    }

    .mobileOnly {
        display: none;
        height: 0;
        width: 0;

        @media(max-width:$S_Max) {
            display: block;
        }
    }

    /* #endregion */
    /****************************************************************************************************/

    /****************************************************************************************************/
    /* #region Utility Classes - Txt Colour + BG Colour */
    /****************************************************************************************************/

    .txtPrimary {
        color: $primaryColour !important;
    }

    .txtSecondary {
        color: $secondaryColour !important;
    }

    .txtTertiary {
        color: $tertiaryColour !important;
    }

    .txtWhite {
        color: black !important;
    }

    .txtBlack {
        color: white !important;
    }

    .txtGrey {
        color: $grey !important;
    }

    .bgPrimary {
        background-color: $primaryColour;
    }

    .bgSecondary {
        background-color: $secondaryColour;
    }

    .bgTertiary {
        background-color: $tertiaryColour;
    }

    /* The most common light background */
    .bgLight {
        background-color: $greyXXLight;
    }

    /* The most common dark background */
    .bgDark {
        background-color: $primaryColour;
    }

    .bgWhite {
        background-color: white;
    }

    .bgOffWhite {
        background-color: $offWhite;
    }

    .bgBlack {
        background-color: black;
    }

    .bgGrey {
        background-color: $grey;
    }

    .bgGreyLight {
        background-color: $greyXXLight;
    }


    /* #endregion */
    /****************************************************************************************************/

    /****************************************************************************************************/
    /* #region Utility Classes - Position, Display, Float */
    /****************************************************************************************************/

    /* Floating */
    .float-none {
        float: none;
    }

    .float-left {
        float: left;
    }

    .float-right {
        float: right;
    }

    /* Positioning */
    .position-absolute {
        position: absolute;
    }

    .position-relative {
        position: relative;
    }

    .position-fixed {
        position: fixed;
    }

    /* Display */
    .display-flex {
        display: flex;
    }

    .display-block {
        display: block;
    }

    .display-inline-block {
        display: inline-block;
    }

    .display-inline {
        display: inline;
    }

    .display-none {
        display: none;
    }

    /* #endregion */
    /****************************************************************************************************/

    /****************************************************************************************************/
    /* #region Utility Classes - Text Alignment */
    /****************************************************************************************************/

    .txt-c {
        text-align: center !important;
    }

    .txt-l {
        text-align: left !important;
    }

    .txt-r {
        text-align: right !important;
    }

    .txt-t {
        vertical-align: top !important;
    }

    .txt-b {
        vertical-align: bottom !important;
    }

    .txt-m {
        vertical-align: middle !important;
    }

    /* Table cell content alignment */

    /* horizontal alignment */
    table .txt-c,
    table tr .txt-c,
    table tr td.txt-c {
        @extend .txt-c;
    }

    table .txt-l,
    table tr .txt-l,
    table tr td.txt-l {
        @extend .txt-l;
    }

    table .txt-r,
    table tr .txt-r,
    table tr td.txt-r {
        @extend .txt-r;
    }

    /* vertical alignment */
    table .txt-t,
    table tr .txt-t,
    table tr td.txt-t,
    table tr th.txt-t {
        @extend .txt-t;
    }

    table .txt-b,
    table tr .txt-b,
    table tr td.txt-b,
    table tr th.txt-b {
        @extend .txt-b;
    }

    table .txt-m,
    table tr .txt-m,
    table tr td.txt-m,
    table tr th.txt-m {
        @extend .txt-m;
    }

    /* #endregion */
    /****************************************************************************************************/

    /****************************************************************************************************/
    /* #region Utility Classes - Whitespace (padding + margin) */
    /****************************************************************************************************/

    /*
    Examples:
    .mtxl - margin-top-extra-large (add an extra large margin-top)
    .pan  - padding-all-none       (remove padding on all sides)
    .mvs  - margin-vertical-small  (add small margins on the top and bottom)
*/

    @include whitespace(($whitespace-xs, $whitespace-s, $whitespace-m, $whitespace-l, $whitespace-xl, $whitespace-xxl, $whitespace-xxxl, $whitespace-xxxxl));

    /* #endregion */
    /****************************************************************************************************/

    /****************************************************************************************************/
    /* #region Utility Classes - Misc */
    /****************************************************************************************************/
    /* (simple, single-purpose classes intended for use in Siteifnity editors, etc) */
    .border {
        border: $border;
    }

    .borderTop {
        border: solid 1px $tertiaryColour;
    }

    .borderRightHighlight {
        border-right: solid 2px $tertiaryColour;
    }

    .borderBottomShort {
        @extend %borderBottomShort;
    }

    .equalTopMargins {
        > :first-child {
            margin-top: 1rem;
        }
    }

    img.baseImage,
    .baseImage img {
        display: block;
        width: 100%;
    }

    .pullQuote {
        padding: 30px 50px;
        border-top: 1px solid black;
        border-bottom: 1px solid black;
        font-size: 1.3em;
        font-weight: $fontWeightBold;
    }

    .subtle {
        color: lighten($primaryColour, 15%);
        font-size: 0.8em;
    }

    .hz-lazy-load-container {
        position: absolute;
        z-index: 2;
        top: 50%;
        transform: translatey(-50%);
        width: 100%;

        .hz-button {
            position: absolute;
            border: solid 1px $primaryColour;
            padding: .5rem .75rem;
            color: $primaryColour;
            font-size: .8rem;

            &.hz-prev {
                left: 1rem;
            }

            &.hz-next {
                right: 1rem;
            }

            &:hover {
                background-color: $tertiaryColour;
                color: #fff;
                border-color: $tertiaryColour;
            }
        }
    }



    /* #endregion */
    /****************************************************************************************************/
    /****************************************************************************************************/
    /* #region Skip-to-Content + Sticky Links */
    /****************************************************************************************************/
    #skiptocontent {
        a {
            left: -999px;
            position: absolute;
            top: auto;
            width: 1px;
            height: 1px;
            overflow: hidden;
            z-index: -999;

            &:focus,
            &:active {
                top: auto;
                left: auto;
                width: 30%;
                height: auto;
                z-index: 999;
                overflow: auto;
                margin: 10px 35%;
                padding: 5px;
                color: white;
                font-size: 1.2rem;
                text-align: center;
                border: 4px solid $selectionColor;
                border-radius: 15px;
                background-color: black;
            }
        }
    }

    /* Sticky Phone Link */
    .stickyPhone {
        top: auto;
        left: auto;
        right: 1rem;
        bottom: 5%;
        margin: 0 1rem;
        z-index: 10;
        position: fixed;
        color: white;
        font-size: 2rem;
        transition: $transition;

        @media (min-width: $M_Min) {
            display: none;
        }

        a {
            @include button;
            width: 2rem;
            height: 2rem;
            min-width: initial;
            display: flex;
            overflow: hidden;
            margin: 0;
            padding: 0;
            color: inherit;
            text-align: center;
            border-radius: 50%;
            background-color: $tertiaryColour;
            // phone icon
            .fas {
            }
        }
    }

    /* Scroll-to-Top Link */
    .scrollToTop {
        top: auto;
        left: auto;
        right: 5rem;
        bottom: 3rem;
        opacity: 0.4;
        display: none;
        z-index: 1000;
        position: fixed;
        font-size: 1.2rem;
        cursor: pointer;
        padding: .25rem .5rem;
        border: 3px solid white;
        border-radius: 999px;
        background-color: $secondaryColour;

        @media(max-width:$S_Max) {
            right: 2rem;
        }

        @include focusAndHover {
            opacity: 1;
            text-decoration: none;
            background-color: $primaryColour;
        }

        span {
            width: 100%;
            color: white;
            text-align: center;
        }
    }

    /* #endregion */
    /****************************************************************************************************/

    /****************************************************************************************************/
    /* #region Social & Sharing Links */
    /****************************************************************************************************/

    .printAndShare {
        display: flex;
    }

.dark ul.socialLinks,
.dark .cmsContent ul.socialLinks {
    @include grid(auto, 1rem, "li");
    font-size: 1.2rem;
    flex-wrap: nowrap;
    justify-content: flex-start;
    list-style: none;
    margin: 0;

    li {
        list-style: none;
        margin-bottom: 0;
        display: flex;
        align-items: center;

        a:link,
        a:visited,
        a:active {
            color: #fff;
        }

        a:hover {
            color: $tertiaryColour;
        }

        &:first-child {
            margin-left: 0;
        }
    }
}

    /** ShareThis (social sharing links) *****************************************************************/
    .shareThis {
        .sharingLinks {
            display: none;
        }
    }

    // Sharing links (ShareThis tooltip/modal)
    ul.sharingLinks {
        background-color: white;
        display: flex;
        /*@include grid(auto, 1rem, "li");*/
        li {
            button {
                cursor: pointer;
                border: none;
                padding: 0;
                background-color: transparent;
                background-image: none;
                color:$primaryColour;
                font-size: .5rem;
                span {
                    margin-left:.5rem;
                }
                svg {
                    font-size: 1rem;
                }

                i {
                    // icons
                    color: inherit;
                }

                &:hover,
                &:focus &:active {
                    color: $grey;
                    background-color: transparent;
                }
            }
        }

        &.popup_content {
            padding: 1rem 2rem 1rem 1rem;
        }
    }

    /* #endregion */
    /****************************************************************************************************/

    /****************************************************************************************************/
    /* #region Images */
    /****************************************************************************************************/

    img {
        width: auto;
        height: auto;
    }

    /* As BACKGROUND image */
    .bgImgWrapper {
        @include aspectRatioBox(16 9, ".bgImg");
        max-width: 100%;
        z-index: 0;
        overflow: hidden;
        position: relative;

        .bgImg {
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
        }

        a {
            width: 100%;
            height: 100%;
            display: block;
        }
    }

    /* As a NATIVE IMG element */
    .imgWrapper {
        width: 100%;

        a {
            img {
                max-width: 100%;
            }
        }

        img {
            max-width: 100%;
            height: 100%;
            width: 100%;
            aspect-ratio: auto;
            object-fit: cover;
        }
    }

    /* Hover Zoom class */
    $bgImageHoverZoom: 10%;

    .bgImgWrapper.hoverZoom {
        .bgImg {
            transition: all 0.3s;

            @include focusAndHover {
                transform: scale(1.1);
            }
        }
    }

    .coverImg {
        @extend %hugParent;
    }

    /* #endregion */
    /****************************************************************************************************/

    /****************************************************************************************************/
    /* #region FullScreenImages (cover images) */
    /****************************************************************************************************/
    .backstretch {
        .overlay {
            inset: 0;
            position: absolute;
        }

        @include gradientOverlay;
    }

    /* #endregion */
    /****************************************************************************************************/

    /****************************************************************************************************/
    /* #region Iframes + Videos + Embeds */
    /****************************************************************************************************/

    .embedWrapper {
        position: relative;
        padding-bottom: 56.25%;
        /* 16:9 */
        padding-top: 25px;
        height: 0;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    /* #endregion */
    /****************************************************************************************************/

    /****************************************************************************************************/
    /* #region Mediums */
    /****************************************************************************************************/

    /* Single Medium */
    .cardView.medium {
        .innerContent {
            /*.itemImage {
            position: relative;

            &:before {
                // play button over top of the video thumb
                top: calc(50% - 1rem);
                left: calc(50% - 1rem);
                z-index: 1;
                position: absolute;
                color: $tertiaryColour;
                font-size: 2rem;
            }
        }*/
            border-top: solid 1px $tertiaryColour;
            border-bottom: solid 1px $tertiaryColour;
            padding: 1.5rem 0;
        }
    }

    /* Default List */
    .mediums {
        ul.grid {
            li.col {
            }
        }

        .medium {
        }
    }

    /* #endregion */
    /****************************************************************************************************/

    /****************************************************************************************************/
    /* #region Alternate CardView Layout */
    /****************************************************************************************************/

    /* Single Medium */
    .twoColumn {
        .listView {
            gap: 2rem;
        }

        .cardView {
            .itemImage {
                .imgWrapper {
                    img {
                        aspect-ratio: 3/2;
                    }
                }
            }

            .innerContent {
                display: flex;
                gap: 3rem;
                border: none;

                @media(max-width:$L_Max) {
                    flex-direction: column;
                }

                .itemImage {
                    flex-basis: 40%;
                }

                .itemContent {
                    flex-basis: 60%;

                    h3 {
                        margin-top: 0;
                    }
                }
            }
        }
    }



    /* #endregion */
    /****************************************************************************************************/

    /****************************************************************************************************/
    /* #region Documents */
    /****************************************************************************************************/

    .document {
        font-size: 0.8rem;
        background-color: $greyXXXLight;

        a {
            width: 100%;
            display: block;
            padding: 1rem 1.4rem 0.8rem 1.4rem;
            text-decoration: none !important;
            border-bottom: 1px solid $primaryColour;

            .iconExtension {
                width: 1.5rem;
                flex-grow: 0;
                flex-basis: auto;
                align-items: center;
                justify-content: center;
                color: $primaryColour;

                svg {
                    color: inherit;
                    font-size: 2rem;
                }
            }

            .documentDetails {
                .titleLink {
                    margin: 0;
                    padding: 0;
                    font-size: 1.2rem;
                }

                .grid {
                    @include grid("auto");
                    display: flex;
                }

                span.col {
                    margin: 0 1rem 0 0;
                    flex-basis: auto;
                    flex-grow: 0;
                }

                span.size {
                }

                span.lastModified {
                }
            }

            .typeIcon {
                display: block;
            }

            .hoverIcon {
                display: none;
            }

            .textExtension {
                font-size: 1rem;
            }

            @include focusAndHover {
                background-color: darken($greyXXXLight, 5%);
                border-color: $secondaryColour;

                .grid {
                    .col.iconExtension {
                        color: $secondaryColour;

                        svg.typeIcon {
                            display: none;
                        }

                        svg.hoverIcon {
                            display: block;
                        }
                    }
                }
            }
        }
    }

    .documents {
        margin: 1rem 0;



        ul {
            li {
                color: $primaryColour;
                display: block;
                margin: 0;
                font-size: 1rem;
                transition: $transition;
                background-color: transparent;
            }
        }
    }

    /* #endregion */
    /****************************************************************************************************/

    /****************************************************************************************************/
    /*#region TYPOGRAPHY CMS Content Styles */
    /****************************************************************************************************/

    // CMS CONTENT STYLES
    .cmsContent {
        color: $bodyFontColour;
        font-size: 1rem;
        line-height: $lineHeight;

        img {
            max-width: 100%;
        }

        em,
        i {
            font-style: italic;

            &.highlight {
                font-style: normal;
                color: $tertiaryColour;
            }
        }

        h2 em,
        h2 i {
            font-style: normal;
            color: $tertiaryColour;
        }

        strong,
        b {
            font-weight: 700;
        }
        /* Headings */
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        .h {
            color: $primaryColour;

            &:first-child {
                margin-top: 0;
            }
        }
        /* Links */
        a {
            color: $secondaryColour;
            text-decoration: none;

            &:hover,
            &:focus {
                text-decoration: underline;
            }

            &:active {
                color: darken($secondaryColour, 10%);
                text-decoration: underline;
            }
        }
        // external links
        /*    a[1="_blank"]:not(.button):after {
        content: " ";
        position: relative;
        display: inline-block;
        font-size: .6em;
        padding-left: .5rem;
    }*/
        .socialLinks a[target="_blank"]:after {
            content: none;
        }
        /* Paragraphs */
        p {
            line-height: $lineHeight;
        }
        /* SHARED list styles */
        ul,
        ol {
            margin: 1rem 0 1rem 1rem;
            /* SHARED list item styles */
            li {
                margin: 0 0 0.8em;
                position: relative;
                padding: 0 0 0 1.75rem;
            }
            /* SHARED nested list styles */
            ol,
            ul {
                margin: 0.4rem 0;

                > li {
                    background: none;


                    &:before {
                        color: $bodyFontColour;
                    }
                }
            }
            /* Special lists:   Alpha & Roman Numerals */
            // just add classes "roman" or "alpha" to <ol> or <ul> in content editor
            &.roman {
                > li {
                    padding-left: 2rem;

                    &:before {
                        content: counter(customListCounter, lower-roman) ".";
                    }
                }
            }

            &.alpha,
            &.lettered {
                > li {
                    &:before {
                        content: counter(customListCounter, lower-alpha) ".";
                    }
                }
            }

            &.upper,
            &.uppercase {
                // capital letters
                > li {
                    &:before {
                        text-transform: uppercase;
                    }
                }
            }
            // reset to NON LIST STYLES
            &.reset {
                li {
                    padding-left: 0;

                    &:before {
                        display: none;
                    }
                }
            }
        }
        /* Unordered Lists Styles ONLY */
        /* Make sure what you're adding belongs here, and not in the SHARED list styles above */
        ul {
            list-style-type: none;

            > li {
                list-style-type: none;
                position: relative;
                /* bullet icon */
                &:before {
                    content: ' ';
                    top: initial;
                    left: 0;
                    width: 1em;
                    height: 1.4em; // needs to match the height of a single line of the li (in order to keep it centered with the text)
                    color: $bodyFontColour;
                    content: "";
                    display: block;
                    position: absolute;
                    line-height: inherit;
                    background-image: url(/CFImages/Icons/bullet-square-solid.svg);
                    background-size: 0.4em auto;
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }
        }
        /* Ordered Lists */
        /* Make sure what you're adding belongs here, and not in the SHARED list styles above */
        ol {
            counter-reset: customListCounter 0;
            list-style-position: outside;

            > li {
                list-style-type: none;
                list-style-position: inside;
                counter-increment: customListCounter;

                &:before {
                    top: initial;
                    left: 0.4rem;
                    content: counter(customListCounter) ".";
                    color: $bodyFontColour;
                    position: absolute;
                    font-weight: $fontWeightNormal;
                }
            }
        }
        /* Misc content elements */
        address {
            margin: 0.5rem 0 1rem;
        }

        mark {
            color: white;
            background-color: $selectionColor;
        }

        pre {
            white-space: pre-wrap;
            background-color: #efefef;
            padding: 2rem;
        }

        &.highlight > p:nth-of-type(1) {
            font-size: 1.266rem;
            font-weight: 500;
            line-height: 1.2;
            margin: 0 0 1.38rem;
        }

        &.highlightFirstElement > :first-child {
           /* @extend .h;
            @extend .h3;
            margin: 0 0 1.38rem;

            > * {
                font: inherit;
                line-height: inherit;
            }*/
        }
        /* last child selector */
        /*
    > :last-child {
    }
    */
    }

    // Typically should be paired with .bgDark (for the backgroundColour)
    .dark:not(.notDark, .sfPageEditor),
    .dark:not(.notDark, .sfPageEditor) .cmsContent {
        color: white;

        ::selection {
            background: $selectionColourDark;
            color: white;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        ul li,
        address {
            color: white;
        }

        a:link,
        a:visited {
            color: $tertiaryColour;
        }

        a:hover,
        a:active,
        a:focus {
            color: lighten($tertiaryColour, 10%);
        }

        a.button {
            color: #fff;
            background-color: transparent;
            border: $buttonBorderWidth solid $buttonBorderColour;

            &:hover,
            &:active,
            &:focus {
                background-color: $tertiaryColour;
            }

            &.reverse {
                color: $primaryColour;
                background-color: transparent;
                border: $buttonBorderWidth solid $primaryColour;

                &:hover,
                &:active,
                &:focus {
                    background-color: $tertiaryColour;
                    border-color: $tertiaryColour;
                }
            }
        }
        /* SHARED list styles */
        ul,
        ol {
            /* SHARED nested list styles */
            ol,
            ul {
                > li {
                    &:before {
                        color: white;
                    }
                }
            }
        }
        /* Unordered Lists Styles ONLY */
        /* Make sure what you're adding belongs here, and not in the SHARED list styles above */
        ul {
            > li {
                /* bullet icon */
                &:before {
                    color: white;
                    background-image: url(/CFImages/Icons/bullet-square-solid-white.svg);
                }
            }
        }
        /* Ordered Lists */
        /* Make sure what you're adding belongs here, and not in the SHARED list styles above */
        ol {
            > li {
                &:before {
                    color: white;
                }
            }
        }

        mark {
            color: white;
            background-color: $selectionColourDark;
        }

        pre {
            background-color: $primaryColourDark;
            color: white;
        }

        blockquote {
            background-color: $secondaryColour;
            color: white;

            p {
                color: white;
            }
        }

        .breadCrumbInner {
            color: #fff;
            font-size: .9rem;

            a {
                color: $tertiaryColour;


                &:hover,
                &:focus {
                    text-decoration: underline;
                }
            }

            > * {
                &:last-child {
                    color: $greyXLight;
                }

                &:not(:last-child) {
                    color: $tertiaryColour;
                }
            }
        }
    }

    /* #endregion */
    /****************************************************************************************************/
    /****************************************************************************************************/
    /* #region Icons / FontAwesome / Kendo Icons */
    /****************************************************************************************************/
    /* A trick for prefetching icons we know we need to prevent the flash-of-unstyled-icon on hover */
    body::after {
        position: absolute;
        width: 0;
        height: 0;
        overflow: hidden;
        z-index: -1;
        content: url(/CFImages/Icons/chevron-down.svg) url(/CFImages/Icons/chevron-up.svg) url(/CFImages/Icons/audio.svg) url(/CFImages/Icons/plus.svg) url(/CFImages/Icons/minus.svg);
    }

    /* Any and all FontAwesome icons */
    [class^='fa-'],
    [class*=' fa-'] {
        color: inherit;
    }

    /* shared Kendo widget icon styles (works with navigation css file) */
.k-icon {
    // NOTE: for better ux on touch devices, keep the button fairly large and instead modify the bg image size
    width: 3rem;
    height: 3rem;

    &:before {
        content: ' ';
        @include hugParent;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 0.6rem;
    }
    /* Expand / Collapse Arrows */
    &.k-menu-expand-arrow:before,
    &.k-i-expand:before {
        background-image: url(/CFImages/Icons/caret-down-solid.svg);

        .k-state-active & {
            background-image: url(/CFImages/Icons/caret-up-solid.svg);
        }
    }

    &.k-i-collapse:before {
        background-image: url(/CFImages/Icons/minus-white.svg);

        .k-state-active & {
            background-image: url(/CFImages/Icons/plus-white.svg);
        }
    }
}

    /* selected element icon (needs to be white because dark background */
    li.k-item.selected {
        > div .k-icon:first-of-type {
            &.k-i-expand:before {
                background-image: url(/CFImages/Icons/plus-white.svg);
            }

            &.k-i-collapse:before {
                background-image: url(/CFImages/Icons/minus-white.svg);
            }
        }
    }

    /* Custom icons element with background svgs*/
    .icon {
        display: inline-block;
        font-size: 1em;

        &:before {
            content: ' ';
            width: 1.4em;
            height: 1.4em;
            display: inline-block;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }

        &.audio {
            &:before {
                background-image: url(/CFImages/Icons/audio.svg);
            }
        }

        &.print {
            &:before {
                background-image: url(/CFImages/Icons/print.svg);
            }
        }

        &.bookmark {
            &:before {
                background-image: url(/CFImages/Icons/bookmark.svg);
            }
        }

        &.share {
            &:before {
                background-image: url(/CFImages/Icons/share.svg);
            }
        }

        &.vcard {
            &:before {
                background-image: url(/CFImages/Icons/vcard.svg);
            }
        }

        &.video {
            &:before {
                background-image: url(/CFImages/Icons/video.svg);
            }
        }
    }

    /* #endregion */
    /****************************************************************************************************/
    /****************************************************************************************************/
    /* #region Layout / Structure */
    /****************************************************************************************************/
    /*Sticky Footer Items */
    body {
        display: flex;
        flex-direction: column;
    }

    #wrapper {
        //overflow: hidden; // hide unwanted scrollbars
        height: auto !important;
        flex: 1 0 auto;
        padding-top: 9rem; // replaces the old JS logic

        .k-ie11 & {
            flex: inherit;
        }
    }

    .pageWrapper {
        position: relative;
        transition: $transition;
        left: 0;
    }

    .sfPageEditor{
        .opacityWrapper {
            > * {
                opacity: 1 !important;
                transform: translatey(0) !important;
            }
        }
    }

    .k-safari {
        .opacityWrapper {
            > * {
                opacity: 1 !important;
                transform: translatey(0) !important;
            }
        }
    }

.opacityWrapper {
    padding: 4rem 0rem;
    position: relative;

    &:not(.forceShow) {
        > * {
            opacity: 0;
            transform: translatey(10%);
            transition: opacity 1s, transform .5s;
        }
    }


    &.noPadding {
        padding: 0;
    }

    &.inViewport {
        > * {
            opacity: 1;
            transform: translatey(0);
        }

        h2 {
            &:after {
                width: 3rem;
            }
        }
    }
}

    .fullPhotoWrapper {
        width: 100%;
        height: 100%;
        overflow: hidden;
        min-height: 500px;
        text-align: left;

        h2 {
            position: absolute !important;
            z-index: 2;
            color: #fff;
            left: 50%;
            transform: translatex(-50%);
            padding-top: 2rem;
        }

        .primaryWrapper {
            width: 100%;
        }

        .button {
            border: solid 1px $primaryColour;
        }

        .cardView.insight {
            padding: 9rem 0 2rem;
        }

        .imgWrapper {
            @include gradientOverlay();
            height: 100%
        }


        .splide__track {
            height: 100%;
        }


        .itemImage {
            position: absolute;
            object-fit: contain;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;

            .imgWrapper {
                @include gradientOverlay();

                img {
                    transition: $transition;
                }
            }
        }

        .is-active {
            .itemImage {

                .imgWrapper {
                    img {
                        transform: scale(1.2);
                    }
                }
            }
        }
    }

    .primaryWrapper {
        background-color: $primaryColour;
    }

    .secondaryWrapper {
        background-color: $secondaryColour;

        .cardView {
            .innerContent {
                .itemContent {
                    .contentTypeLabels {
                        color: $tertiaryColour;

                        a {
                            color: $tertiaryColour;

                            &.childSubType {
                                border-left: solid 1px #fff;
                                color: #fff;
                            }
                        }
                    }

                    h3 a {
                        color: #fff;
                    }

                    a {
                        color: $tertiaryColour;
                    }

                    .dates {
                        color: $tertiaryColour;
                    }
                }
            }
        }

        .splide.wrapper {
            .sliderFooter .splide__arrows .splide__arrow {
                color: #fff;
                border-color: #fff;
            }
        }
    }

    .whiteWrapper {
        background-color: #fff;
    }

    .greyWrapper {
        background-color: $greyXXLight;

        h2 {
            color: $primaryColour !important;
        }
    }

    .studentsWrapper.fullPhotoWrapper {
        h2 {
            position: relative !important;
        }
    }

    .darkGreyWrapper {
        background-color: $greyXLight;

        .button {
            border-color: $secondaryColour;
        }

        .tabstrip.k-widget {
            &.k-tabstrip {
                .k-tabstrip-items {
                    // the ul
                    justify-content: center;
                    gap: 1rem;

                    .k-item {
                        //the li (e.g. tabs)


                        .k-loading {
                            border: none;
                        }

                        .k-link {
                            background-color: #fff;
                            padding: 1rem 2rem;
                            color: $primaryColour;
                            font-family: $secondaryFont;
                            font-size: 1.6rem;
                            border: none;
                        }

                        &.k-first .k-link {
                            padding: 1rem 2rem;
                        }

                        &.k-state-active {
                            .k-link {
                                color: #fff;
                                background-color: $secondaryColour;
                            }
                        }

                        &.k-state-hover {
                            .k-link {
                                background-color: $primaryColour;
                                color: #fff;
                            }
                        }
                    }
                }

                &:focus {
                    box-shadow: none;
                }

                @include focusVisible {
                    outline: $outline;
                    outline-color: $greyLight;
                    outline-style: dashed;

                    .k-item.k-state-active {
                        outline: $outline;
                    }
                }

                .k-content {
                    border: none;
                    padding: 0;
                    background-color: transparent;
                    overflow: hidden;

                    &.k-state-active {
                        background-color: transparent;
                    }

                    &:focus {
                        outline: none;
                    }

                    @include focusVisible {
                        outline: $outline;
                        outline-offset: $outline_offset;
                    }
                }
            }
        }
    }

    .linesWrapper {
        background: url(/CFImages/lines.svg);
        background-attachment: fixed;
        border-top: solid 1px #CBD4E6;

        &.greyWrapper {
            background-color: $greyXXLight;
        }

        &.darkGreyWrapper {
            background-color: $greyXLight;
        }

        &.opacityWrapper {
            .button {
                --translate-y: 0px;
                --translate-x: 0px;
                transform: translate(var(--translate-x), var(--translate-y));
            }
        }
    }

    .jumpMenuWrapper {
        position: sticky;
        top: -1px;
        z-index: 5;
        padding: 1rem 0;
        margin-bottom: 2rem;
        background: linear-gradient(to bottom, rgba($primaryColour, 1) 0%, rgba($secondaryColour, 1) 100%); //$greyXXLight;
        filter: drop-shadow(0px 10px 8px #ccc);

        .topSection {
            display: flex;
            justify-content: space-between;
            transition: 300ms;
        }

        .bioPhotoWrapper {
            display: flex;
            opacity: 0;
            transition: $transition;
            visibility: hidden;
            align-items: center;

            img {
                height: 0px;
                width: 0px;
            }

            h4 {
                color: #fff;
                height: 0;
                margin: 0;
                padding: 0;
                font-size: 1.1rem;
            }
        }

        .animationWrapper {
            position: relative;
            right: 50%;
            transform: translateX(50%);
            transition-duration: 400ms;
        }

        .jumpMenu {
            display: flex;
            width: 100%;
            padding: 1rem 0;
            justify-content: center;
            align-items: center;
            position: relative;
            left: 0;
            gap: 2rem;
            transition: $transition;

            a {
                text-decoration: none;
                font-size: 1.4rem;
            }
        }

        &.is-pinned {

            .bioPhotoWrapper {
                opacity: 1;
                visibility: visible;
                transition: $transition;

                img {
                    height: 60px;
                    width: auto;
                }

                h4 {
                    height: auto;
                }
            }

            .animationWrapper {
                position: relative;
                right: 0%;
                transform: translateX(0%);
            }
        }


        a {
            font-family: $secondaryFont;

            &:link,
            &:visited,
            &:active {
                color: #fff;
            }

            &:hover {
                color: $tertiaryColour;
            }
        }
    }
    /* #endregion */
    /****************************************************************************************************/
    /****************************************************************************************************/
    /* #region Containers */
    /****************************************************************************************************/
    .container {
        width: $container;
        max-width: 100%;
        margin: 0 auto;
        position: relative;
        // Control whether containers are always inset from the window.
        // If false, you'll need to always make sure to provide a top-level .container.full for padding
        $containers_always_inset: true;

        @if($containers_always_inset) {
            max-width: calc(100vw - (#{$fullWidthPadding} * 2) - var(--scrollbar-width));

            @media(max-width:$L_Max) {
                max-width: calc(100vw - (#{$desktopPadding} * 2) - var(--scrollbar-width));
            }

            @media(max-width:$M_Max) {
                max-width: calc(100vw - (#{$tabletPadding} * 2) - var(--scrollbar-width));
            }

            @media(max-width:$S_Max) {
                max-width: calc(100vw - (#{$mobilePadding} * 2) - var(--scrollbar-width));
            }

            @media(max-width:$XS_Max) {
                max-width: calc(100vw - (#{$xsPadding} * 2) - var(--scrollbar-width));
            }
        }

        .container {
            padding-left: 0;
            padding-right: 0;
        }

        &.wide {
            width: calc(#{$container_wide});
        }

        &.narrow {
            width: calc(#{$container_narrow});
        }
        // spans the full width of the parent, but adds wide horizontal gutters/padding
        &.full {
            width: 100%;
            max-width: 100%;
            @extend %fullWidthPadding;

            > .container.full:not(.force) {
                padding-left: 0;
                padding-right: 0;
            }

            &.force {
                width: calc(100vw - var(--scrollbar-width));
            }
        }

        &.edge {
            width: 100vw;
            max-width: calc(100vw); // fallback
            max-width: calc(100vw - var(--scrollbar-width));
            left: 50%;
            position: relative;
            transform: translateX(-50%);
            margin-left: 0;
            margin-right: 0;

            &.full {
                width: 100vw;
                max-width: 100vw;
            }

            *.container.full {
                @extend %fullWidthPadding;
            }
        }

        &.force {
            max-width: 100vw;
            left: 50%;
            position: relative;
            transform: translateX(-50%);
            margin-left: 0;
            margin-right: 0;
        }
    }
    /* pad an element, pushing content in to align with the main container - this needs to be updated to match global padding settings*/
    $known_breakpoint: 1574px; // for now, this is just set manually to a magic number. Whatever the width is when we need to switch to global padding.

    .padLeft {
        padding-left: calc((100vw - #{$container}) / 2) !important; // fallback for browsers that don't support custom variables
        padding-left: calc((100vw - #{$container} - var(--scrollbar-width)) / 2) !important;

        @media(max-width: $known_breakpoint) {
            padding-left: $largeDesktopPadding !important;
        }

        @media(max-width:$L_Max) {
            padding-left: $desktopPadding !important;
        }

        @media(max-width:$M_Max) {
            padding-left: $tabletPadding !important;
        }

        @media(max-width:$S_Max) {
            padding-left: $mobilePadding !important;
        }

        @media(max-width:$XS_Max) {
            padding-left: $xsPadding !important;
        }
    }

    .padRight {
        padding-right: calc((100vw - #{$container}) / 2) !important; // fallback for browsers that don't support custom variables
        padding-right: calc((100vw - #{$container} - var(--scrollbar-width)) / 2) !important;

        @media(max-width: $known_breakpoint) {
            padding-right: $largeDesktopPadding !important;
        }

        @media(max-width:$L_Max) {
            padding-right: $desktopPadding !important;
        }

        @media(max-width:$M_Max) {
            padding-right: $tabletPadding !important;
        }

        @media(max-width:$S_Max) {
            padding-right: $mobilePadding !important;
        }

        @media(max-width:$XS_Max) {
            padding-right: $xsPadding !important;
        }
    }
    /* #endregion */
    /****************************************************************************************************/
    /****************************************************************************************************/
    /*#region Site Header */
    /****************************************************************************************************/
    #header {
        top: 0;
        left: 0;
        width: 100%;
        z-index: 10;
        position: absolute;
        transition: $transition;

        &.up {
            position: fixed;
            animation: header-slide-in 300ms ease;
            background: linear-gradient(to bottom, rgba(darken($primaryColour, 10%), 1) 0%, rgba($secondaryColour, 1) $bodyBackgroundGradientHeight);
        }

        &.up.top {
            background: none;
        }

        .searchHiddenWrapper {
            /* refer to "region Site Search" styles below */
            display: none;
        }

        .topHeader {
            position: relative;
            margin-top: 1rem;

            .logo {
                margin-top: 2rem;

                img {
                    width: 18rem;
                    height: auto;
                    max-width: inherit;
                }
            }

            .social {
                display: grid;
                grid-template-columns: 1fr 3fr;
                margin-bottom: 1.4rem;
                gap: 1.5rem;
            }

            .searchExpand {
                color: $tertiaryColour;
                cursor: pointer;
                margin: 0;
                padding: 0;
                border: none;
                background: none;
                font-size: 1.1rem;

                &:hover {
                    color: #fff;
                }
            }
        }

        .header {
            padding: 0;
            color: white;
            padding: 1.6rem 0 .5rem;
            position: relative;
        }
    }
    /* #endregion */
    /****************************************************************************************************/
    /****************************************************************************************************/
    /*#region Page Topper (Banner) */
    /****************************************************************************************************/
    .topper {
    }

    .topperSection {
        // For detail pages without taglines
        position: relative;
        margin: 0;
        text-align: left;

        .meta {
            text-transform: uppercase;
            font-size: 0.8rem;

            .separator {
                margin: 0 0.4rem;
            }
        }

        .contentTypeLabels {
            font-size: inherit;
        }

        h1 {
            font-size: 4rem;
            margin-top: 0;
            color: #fff;
        }

        h2.topperSubtitle {
            text-align: center;
        }

        .topperTagLine,
        h5 {
            text-align: center;
            color: $tertiaryColour;
        }

        .topperPageHighlightText, .topperPageHighlightText p {
            text-align: center;
            margin: 2rem auto 0;
            /* width: 100%; */
            display: block;
            color: $tertiaryColour;
            font-weight: bold;
            font-size: 1.2rem
        }

        hr {
            margin: 2rem 0 0;
        }
        //base topperContent styles
        .topperContent {
            padding: 3rem 0;
            text-align: left;

            &.greyWrapper {
                text-align: center;

                h1 {
                    color: $primaryColour;
                }
            }

            h1 {
                color: #fff;
                margin-bottom: 2.4rem;

                &:after {
                    content: '';
                    position: absolute;
                    height: 6px;
                    width: 3rem;
                    background-color: $tertiaryColour;
                    left: 0;
                    bottom: -1.25rem;
                    transition: .2s ease-in .4s;
                }
            }

            h2.topperSubtitle {
                text-align: center;
                margin-top: 3rem;
            }

            .topperTagLine {
                width: 50%;
                text-align: center;
                margin: auto;
            }

            .sourceName {
                color: $tertiaryColour;
            }
        }
        // If has an image
        &.hasImage {
            overflow: hidden;
            min-height: 500px;
            background-color: $secondaryColour;

            .topperImage {
                position: absolute;
                height: 100%;
                width: 100%;

                .imgWrapper {
                    height: 100%;
                    width: 100%;

                    &:after {
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 200%;
                        content: "";
                        z-index: 0;
                        position: absolute;
                        mix-blend-mode: darken; //hard-light multiply color-burn;
                        /* filter: saturate(1.5); */
                        //background: radial-gradient(circle at 85% 25%, rgba(#000, 0) 15%, rgba(#000, .5) 42%, #000 100%);
                        background: rgba(0,0,0,.4);
                        /* opacity: .5;*/
                    }
                }
            }

            .topperContent {
                h1 {
                    color: #fff;

                    &:after {
                        content: '';
                    }
                }

                .meta {
                    color: $tertiaryColour;
                }

                .authorTeam {
                    max-width: 600px;
                }
            }
        }
        //if doesn't have an image
        &.hasNoImage {
            .topperContent {
                h1 {
                    &:after {
                        content: none;
                    }
                }
            }

            .authorTeam {
                margin-bottom: 1rem;

                a {
                    color: $primaryColour;

                    &:hover,
                    &:focus {
                        color: $tertiaryColour;
                    }
                }
            }
            /* print / share buttons */
            .printAndShare {
                justify-content: center;

                .button {
                    color: $tertiaryColour;
                    font-size: 1.2rem;
                    padding: .25rem;
                    border: none;

                    [class*="fa-"] {
                        margin-left: 0;
                    }

                    &:hover {
                        color: $secondaryColour;
                        background-color: transparent;
                        border: none;
                    }
                }
            }
        }
    }

    .smallBanner .hasImage {
        min-height: 240px;
    }
    /* #endregion */
    /****************************************************************************************************/
    /****************************************************************************************************/
    /*#region Site Main Content */
    /****************************************************************************************************/

    .breadCrumb {
        font-size: 0.9rem;
        display: flex;
        color: #fff;
        padding: 2rem 0;

        a {
            color: $primaryColour;
            text-decoration: none;
            font-weight: $fontWeightNormal;

            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }

        > * {
            &:last-child {
                color: $grey;
            }

            &:not(:last-child) {
                color: $primaryColour;
                font-weight: $fontWeightNormal;
            }
        }

        span.separator {
        }

        span.current {
        }
    }

    main {
        // Should match the height of the header (for fixed menu on scroll up)
        .bd {
            .mainContent {

                &.opacityWrapper {
                    padding-bottom: 2rem;
                }
                // Force Max width of image in content blocks and allow for scaling on responsive
                .sfContentBlock {
                    img {
                        max-width: 100%;
                    }
                }
            }

            .sideContent {
                padding: 2rem 0;
            }
        }
    }

    .notDark main .bd {
        background-color: $greyXXLight;
    }
    /* #endregion */
    /****************************************************************************************************/
    /****************************************************************************************************/
    /*#region Site Footer */
    /****************************************************************************************************/
    /* all footer sections */
    /* NOTE padding/margins are controlled on the template containers using Whitespace mixin utility classes of mvxl or pvxl etc */
.siteFooter {
    position: relative;
    z-index: 1;

    .preFooter {
        background-color: $secondaryColourTransparent;

        .preFooterGrid {
            .preFooterLeft {
            }

            .preFooterRight {
            }
        }

        .subMenuWrapper {
            .mainTreeView {
                max-width: 600px;
                border: none;

                .k-item {
                    margin-bottom: 2px;

                    &.selected {
                        background-color: rgba($tertiaryColour, .8);

                        .k-link {
                            &:after {
                                background-color: #fff;
                            }
                        }
                    }

                    .k-link {
                        border: none;
                        font-size: 1.5rem;
                        padding-bottom: 1rem;
                        color: #fff;
                        @include titleUnderline;

                        &:after {
                            bottom: .5rem;
                            left: inherit;
                            width: 2rem;
                            transition: none;
                        }
                    }

                    &:hover {
                        background-color: rgba($tertiaryColour, .8);

                        .k-link {
                            &:after {
                                background-color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }

    .mainFooter {
        background-color: $primaryColour;
        padding: 2rem 0 0;

        p,
        nav.topLevelSimple {
            //opacity: .8;
            margin: 0;
        }

        a {
            &:hover {
                color: #fff;
                text-decoration: none;
                opacity: 1;
            }
        }

        .socialLinks {
            a {
                color: #fff;
                font-size: 2rem;
                padding-right: .5rem;
            }

            a:hover {
                opacity: 1;
            }
        }

        hr {
            margin: 0.5rem 0 3rem;
            opacity: .6;
        }

        .mainFooterGrid {
            padding-bottom: 1rem;


            .mainFooterLeft {
                img {
                    padding-bottom: 1rem;
                    width: 300px;
                    max-width: 100%;
                }
            }

            .mainFooterRight {
                .socialLinks {
                    padding-bottom: 2rem;
                    font-size: 1.6rem;
                }
            }
        }

        .footerSocials {
            img {
                width: 100%;
            }
        }
    }

    .copyrightFooter {
        // Divs placed inside the container
        .cmsContent {
            font-size: .9rem;
        }
    }
}
    /* #endregion */
    /****************************************************************************************************/
    /****************************************************************************************************/
    /* #region Forms + Inputs / Fields */
    /****************************************************************************************************/
    label {
        cursor: pointer;
    }
    /* Text Fields */
    input {
        color: $greyDark;
        width: 100%;
        height: 3.15rem;
        max-width: 100%;
        padding: 0.2rem 1rem;
        padding-right: 3rem;
        text-overflow: ellipsis;
        border: $border;
        background-color: white;
        font-weight: 100;
        transition: $transitionQuint;

        @include placeholder {
            opacity: 1;
            transition: $transition;
        }
        /*    &:hover, &:focus {
        @include placeholder {
            opacity: 0.4;
        }
    }*/
        @include focusVisible {
            outline: 1px solid $secondaryColour;
            outline-offset: 0;
        }
        // override type-specific styles if needed
        &[type='text'],
        &[type='email'],
        &[type='search'],
        &[type='number'],
        &[type='password'] {
        }
    }

    textarea {
        width: 100%;
        min-height: 5rem;
        display: block;
        border: $border;
        font-weight: 100;

        &:hover,
        &:focus {
            @include placeholder {
                opacity: 0.4;
            }
        }

        &:focus {
            outline: 1px solid $secondaryColour;
            outline-offset: 0;
        }
    }
    /* Select Lists */
    select {
        width: 100%;
        height: 3.15rem;
        max-width: 100%;
        color: $greyDark;
        cursor: pointer;
        border: $border;
        padding: 0.2rem 1rem;
        padding-right: 1.6rem;
        font-size: 1rem;
        font-weight: 100;
        line-height: 1;
        -moz-appearance: none;
        /* Firefox */
        -webkit-appearance: none;
        /* Safari and Chrome */
        appearance: none;
        background-size: 0.8rem auto;
        background-image: url(/CFImages/Icons/plus.svg);
        background-repeat: no-repeat;
        background-position: right 1rem center;
        transition: $transitionQuint;

        &:hover {
            border-color: rgba($primaryColour, 0.7);
        }

        &:focus {
            outline: none;
            outline: 1px solid $secondaryColour;
            outline-offset: 0;
        }

        > option {
            text-transform: none;

            &:checked,
            &:hover {
                background-color: $greyXXLight;
            }
        }
    }
    /* Checkboxes */
    input[type='checkbox'] {
        // Default (unchecked) state
        width: 1px;
        height: 1px;
        margin-right: -1px;
        cursor: pointer;
        position: absolute;
        //visibility: hidden;
        + label {
            cursor: pointer;
            display: inline-block;
            position: relative;
            transition: $transition;
            padding-left: 2rem;

            &::selection {
                color: inherit;
                background-color: transparent; // stop unwanted user highlighting when clicking filters ...
            }



            &:before,
            &:after {
                // fake checkbox using icon
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                display: inline-block;
                position: absolute;
                transition: $transition;
                margin-right: 0.4rem;
                width: 1em;
                height: 1em;
            }

            &:before {
                // background
                content: " ";
                background-color: white;
            }

            &:after {
                // unchecked icon
                content: ' ';
                background-image: none;
                background-repeat: no-repeat;
                background-position: center;
                background-size: 80% auto;
                border: $border;
                border-color: $grey;
            }

            &:hover,
            &:focus {
                color: $secondaryColour;

                &:after {
                    border-color: $secondaryColour;
                }
            }
        }

        &:focus {
            outline: none !important;

            + label {
            }
        }

        &:focus:focus-visible {


            + label {
                outline: $outline;
            }
        }

        &:checked {
            // checked icon
            + label {
                &:after {
                    background-image: url(/CFImages/Icons/check.svg);
                }
            }
        }
        /* disabled */
        $disabledColour: $greyLight;

        &:disabled {
            color: $disabledColour;

            + label {
                color: $disabledColour;

                &:before {
                }

                &:after {
                    filter: grayscale(1);
                    opacity: 0.6;
                    border-color: $disabledColour;
                }

                &:hover,
                &:focus {
                    color: $disabledColour;

                    &:after {
                        border-color: $disabledColour;
                    }
                }
            }
        }
    }
    /* Radio Buttons */
    input[type=radio] {
        height: 1em;
        cursor: pointer;
        display: inline-block;
        width: auto;
    }
    /*  */
    form {
        label {
            cursor: pointer;
        }

        fieldset {
            padding: 0;
            border: none;
        }
        /* Form-only Buttons */
        button {
        }
    }
    /* clears the ‘X’ from Internet Explorer */
    input[type=search]::-ms-clear {
        display: none;
        width: 0;
        height: 0;
    }

    input[type=search]::-ms-reveal {
        display: none;
        width: 0;
        height: 0;
    }
    /* clears the ‘X’ from Chrome */
    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration {
        display: none;
    }
    // required class for HoneyPot field. DO NOT use display: none here.
    .hiddenForHumans {
        position: absolute !important;
        z-index: -10 !important;
        pointer-events: none !important;
        visibility: hidden !important;
        width: 0;
        height: 0;
    }
    /* #endregion */
    /****************************************************************************************************/
    /****************************************************************************************************/
    /* #region Sitefinity Forms Only */
    /****************************************************************************************************/
    // form container (must work for both <div> and <form> element to work for all SF forms)
    [data-sf-role="form-container"] {
        fieldset {
            margin: 1rem 0;

            legend {
                padding: 0 0.4rem;
            }
        }
    }
    /* Sitefinity File Upload field */
    [data-sf-role="file-field-inputs"] {
        cursor: pointer;

        input[type=file] {
            @include button;

            &::-webkit-file-upload-button {
                visibility: hidden;
                @include sr-only;
            }
        }
    }
    /* #endregion */
    /****************************************************************************************************/
    /****************************************************************************************************/
    /* #region Content Type Labels */
    /****************************************************************************************************/
    .contentTypeLabels {
        color: $primaryColour;
        display: inline-block;
        @include smallcaps;
        letter-spacing: $letterSpacing;
        font-size: 0.7rem;
        margin-bottom: 1.4rem;

        span.labelValuesSeperator {
            &:before {
                content: " | ";
                color: $tertiaryColour;
            }
        }
        // filter links
        a {
            color: inherit;
            text-decoration: none;

            &.childSubType {
                border-left: solid 1px $secondaryColour;
                margin-left: 6px;
                padding-left: 6px;
            }
        }
    }

    .meta {
        color: $primaryColour;
        font-size: 0.9rem;
        font-weight: $fontWeightNormal;
        letter-spacing: $letterSpacing;
        margin-bottom: 0.6rem;

        .separator {
            color: inherit;
            margin: 0 0.2rem;
        }
    }
    /* #endregion */
    /****************************************************************************************************/
    /****************************************************************************************************/
    /* #region Dates */
    /****************************************************************************************************/
    span.dates {
        /*text-transform: uppercase;*/
        font-size: inherit;
        font-weight: inherit;
        letter-spacing: inherit;

        time {
            font-size: inherit;
            /*text-transform: uppercase;*/
        }
    }
    /* #endregion */
    /****************************************************************************************************/
    /****************************************************************************************************/
    /* #region Sliders */
    /****************************************************************************************************/
    /* Splide sliders */
    .splide {
        // fight CLS on most sliders.
        visibility: visible;
        /* Show only the first slide ONLY until the slider is initialized */
        &:not(.is-active) {
            .splide__list {
                width: 100%;
            }

            .splide__slide {
                width: 100%;
                display: flex;

                &:not(:first-child) {
                    display: none;
                }
            }

            .splide__arrow {
                display: none;
            }
        }
        /* slider initialized */
        &.is-active {
            .splide__slide {
                display: flex;
            }
        }

        &.noSliderNeeded {
            .splide__arrows {
                display: none !important;
            }
        }

        .splide__list {
            &.sliderView {
                // fix common issues with listView class
                & > li.splide__slide {
                    margin: 0;
                    width: auto;
                }
            }
        }
        /* Arrows */
        .splide__arrow {
            background-color: transparent;

            &.splide__arrow--next {
                right: inherit;

                svg {
                    //transform: translateX(10%); // makes the FA chevron look more centered
                    margin-left: 0;
                }
            }

            &.splide__arrow--prev {
                left: inherit;

                svg {
                    transform: translateX(-0%); // makes the FA chevron look more centered
                    margin-left: 0;
                }
            }
        }

        &.wrapper {
            position: relative;

            .sliderFooter {
                position: relative;
                display: flex;
                justify-content: flex-end;
                margin-top: 2rem;

                &:has(.viewAllMoreWrapper) {
                    justify-content: space-between;
                }

                .splide__arrows {
                    display: flex;
                    align-self: flex-end;

                    .splide__arrow {
                        position: relative;
                        border: solid 1px $primaryColour;
                        border-radius: inherit;
                        height: 45px;
                        width: 45px;
                        margin: 0 0 0 0.5rem;
                        color: $primaryColour;
                        transform: none;

                        .primaryWrapper & {
                            color: #fff;
                            border: solid 1px #fff;
                        }

                        &:hover {
                            background-color: $tertiaryColour;
                            border-color: $tertiaryColour;
                            color: $primaryColour;
                        }
                    }
                }

                .viewAllMoreWrapper {
                    text-align: left;
                }
            }
        }
    }

    .slider {
        .slide {
            min-height: 500px;
        }
    }
    /* #endregion */
    /****************************************************************************************************/
    /****************************************************************************************************/
    /* #region Single Slide Styles */
    /****************************************************************************************************/
    .slide {
        article {
            .innerContent {
                position: relative;
                height: 100%;

                .itemImage {
                    position: relative;

                    .bgImgWrapper {
                        @include aspectRatioBox(16 9, a);
                    }
                }
            }
        }
    }
    /* #endregion */
    /****************************************************************************************************/
    /****************************************************************************************************/
    /* #region Home Slider Only */
    /****************************************************************************************************/
    // Home Slideshow / Hero slider

    .homePage {
        #wrapper {
            padding-top: 0;
        }

        .pageWrapper {
            // background: url(/CFImages/lines_white.svg);
            // background-attachment: fixed;
        }
    }

    .homeSlideshow {
        position: relative;


        .splide__arrows {
            display: none;
        }

        ul.splide__pagination {
            width: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            right: initial;
            bottom: initial;
            transform: translateX(-50%) translateY(-50%);
            display: flex;
            flex-direction: column;
            width: 90vw;
            align-items: flex-end;
            max-width: calc(100% - 4rem);

            li {
                .splide__pagination__page {
                    width: 0.5rem;
                    height: 0.5rem;
                    margin: 0.5rem 0;
                    background-color: $greyLight;
                    border-radius: 0;

                    &.is-active {
                        transform: scale(1);
                        background-color: #fff;
                    }
                }
            }
        }

        .splide__slide {
            color: $bodyFontColour;
            overflow: hidden;
            position: relative;
            transition: all 1s ease-out;
            /*min-height: 30rem;*/
            //min-height: 52rem;
            /*padding: 6rem 0 3rem;*/
            padding: 6rem 0 9rem;
            padding: 15rem 0 13rem;
            // The slide Image
            .coverImg {
                @include hugParent;
                display: none;
            }

            .innerContent {
                height: 100%;
                display: flex;
                position: relative;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                color: #fff;
                text-align: center;
                /*opacity: 0;*/

                h1 {
                    text-align: center;
                    /*font-size: clamp(2rem, 2.076rem + 2.958vw, 5.625rem);*/
                    font-size: clamp(2rem, 2.076rem + 2.958vw, 4.625rem); // nick reduced size slightly
                    //overflow: hidden;
                    line-height: 1.4;

                    &:after {
                        content: none;
                    }

                    em {
                        font-style: normal;
                        color: $tertiaryColour;
                        /*                    &.slide-in-left {
                        opacity: 0;

                        span {
                        }
                    }*/

                        &.protect {
                            span:first-child {
                                padding: 0 18px;
                                margin-right: -10px;
                            }
                        }
                    }

                    em {
                        /*color: $tertiaryColour;*/
                        display: inline-block;
                        //////
                        /*background-image: linear-gradient( -225deg, #231557 0%, #44107a 29%, #ff1361 67%, #fff800 100% );*/
                        /*background-image: linear-gradient( -225deg, #ffffff 0%, #ffffff 33%, $tertiaryColour 66%, $tertiaryColour 100% );*/
                        background-image: linear-gradient(-225deg, $tertiaryColour 0%, $tertiaryColour 33%, #ffffff 66%, #ffffff 100%);
                        background-size: auto auto;
                        background-clip: border-box;
                        background-size: 300% auto;
                        /*background-position: 0% 0%;*/
                        background-position: 100% 0%;
                        color: #fff;
                        background-clip: text;
                        text-fill-color: transparent;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        animation-fill-mode: forwards;
                        // prevents the animation from running backwards when the active class is removed
                        animation-delay: 1s;
                        animation-play-state: paused;
                        ///
                        transform: translateY(0.3em); // fixes the line-height without causing cut-off text
                    }
                }

                .buttonTextOnly {
                    font-size: 1.4rem;
                    font-weight: $fontWeightThin;
                    //width: 50rem;
                    text-align: center;
                    color: $tertiaryColour;
                    margin-top: 1rem;
                }

                .button {
                    display: inline-block;
                    margin-top: 2rem;
                }
            }

            &.is-active {

                h1 {
                    em {
                        //animation: textclip 2s linear; // original duration (2s)
                        animation: textclip 2.5s linear; // slowed down as per client request
                        animation-fill-mode: forwards;
                    }
                }
                /*            .innerContent {
                h1 {
                    em {
                        &.strengthen {
                            span:first-child {
                                text-shadow: -1.1px -1.1px 1.1px $tertiaryColour, 1.1px -1.1px 1.1px $tertiaryColour, -1.1px 1.1px 1.1px $tertiaryColour, 1.1px 1.1px 1.1px $tertiaryColour;
                                transition: all 1.5s;
                            }
                        }

                        &.slide-in-left {
                            opacity: 1;

                            span {
                                animation: 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) .25s 1 normal both running slide-in-left;
                            }
                        }

                        &.protect {
                            span:first-child {
                                background-repeat: no-repeat;
                                background-image: linear-gradient(to right, $tertiaryColour 100%, $tertiaryColour 100%), linear-gradient(to bottom, $tertiaryColour 100%, $tertiaryColour 100%), linear-gradient(to right, $tertiaryColour 100%, $tertiaryColour 100%), linear-gradient(to bottom, $tertiaryColour 100%, $tertiaryColour 100%);
                                background-size: 100% 3px, 3px 100%, 100% 3px, 3px 100%;
                                background-position: 0 0, 100% 0, 100% 100%, 0 100%;
                                animation: 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) .5s 1 normal both running bg;
                                //animation-play-state: paused;
                            }
                        }
                    }
                }
            }*/
            }

            &.is-visible {
                .innerContent {
                    opacity: 1;
                }
            }
        }
    }

    @keyframes mymove {
        from {
            transform: translatey(-100%);
        }

        to {
            transform: translatey();
        }
    }
    /* #endregion */
    /****************************************************************************************************/
    /****************************************************************************************************/
    /* #region Slider Views (THIS IS ONLY TO CONTROL THE SLIDES and number of ITEMS - NOT THE CARDS) */
    /****************************************************************************************************/
    .sliderView {
    }
    /* #endregion */
    /****************************************************************************************************/
    /****************************************************************************************************/
    /* #region .listView List Views (Filtered Content Lists) THIS ONLY CONTROL THE GRIDS and number of ITEMS - NOT THE CARDS */
    /****************************************************************************************************/
    /******************* Classes 
expertises | sectors (sharedclass: expertiseandsectors)
teammembers
offices
blogitems | cases | eventitems | newsitems | publications | mediums (sharedclass: insights) 
rankings
testimonials
faqs

List Views & Filtered Content Lists
***********************/

    .listView {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(min(var(--card-width), 100%), 1fr));
        gap: var(--card-gap);
        margin-bottom: 2rem;
    }
    /* Filtered Content Lists */
    .filteredContent {
    }

    .listView.globalalerts {
        position: relative;
    }
    /* expertiseandsectors (Includes .expertises .sectors) */
    .listView.expertiseandsectors {
        --card-width: 15rem;
        --card-gap: 0 2rem;
    }

    .simple.expertises ul.listView {
        --card-width: 45rem;
        --card-gap: 0 2rem;
    }


    .listView.teammembers {
        --card-width: 20rem;
        --card-gap: 2rem 2rem;
    }

    .listView.offices {
        --card-width: 30rem;
        --card-gap: 2rem 2rem;
    }
    /* insights (Includes .blogitems .cases .eventitems .newsitems .publications .mediums) */

    .listView.publications {
        --card-width: 100%;
        --card-gap: 0 1rem;
    }

    .listView.newsitems {
        --card-width: 100%;
        --card-gap: 0 1rem;
    }

    .listView.eventitems {
        --card-width: 100%;
        --card-gap: 0 1rem;
    }

    .listView.blogitems {
        --card-width: 100%;
        --card-gap: 0 1rem;
    }

    .listView.mediums {
        --card-width: 100%;
        --card-gap: 0 1rem;
    }

    .listView.cases {
        --card-width: 100%;
        --card-gap: 0 1rem;
    }

    .listView.topics {
        --card-width: 20rem;
        --card-gap: 1rem 2.5rem;
    }

    .listView.rankings {
        --card-width: 18rem;
    }

    .listView.faqs {
    }

    .listView {
        --listViewArticleIndex: none;

        li .cardView {
            animation: fadeInUp;
            animation-duration: .4s;
            animation-fill-mode: forwards;
            animation-timing-function: cubic-bezier(.86, 0, .07, 1);
            animation-delay: calc(var(--listViewArticleIndex)*150ms);
            opacity: 0;
        }
    }
    /* DOWNLOADABLE QR CODES SECTION*/
    .listView.QRCodes {
        --card-width: 100%;

        ul li {
        }

        .cardView.teamQRCode {
            display: flex;
            flex-direction: row;
            gap: 2rem;
            padding: 1rem;
            border: 1px solid #000;
            background-color: #fff;
            color: $bodyFontColour;
            justify-content: space-between;
            align-items: flex-start;

            a {
                color: $bodyFontColour;
            }

            .imgWrapper {
                width: 150px;

                img {
                    object-fit: contain;
                }
            }

            .QRCodes {
                width: 50%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .biolink, .biocardlink {
                    h3 {
                        font-size: 1.1rem;
                        margin: 0.5rem 0;
                        color: inherit;
                    }

                    img {
                        width: 200px;
                        margin: 1rem 0 0 0;
                    }
                }
            }
        }
    }

    @keyframes fadeInUp {
        0% {
            opacity: 0;
            transform: translateY(100%);
        }

        30% {
            opacity: 0;
        }

        100% {
            opacity: 1;
            transform: translateY(-0%);
        }
    }
    // This forces the list view to just be one column regardless
    .listView.oneColumnGrid {
        --card-width: 100%;
        --card-gap: 0 2rem;
    }
    /* Related Content Lists (if different) */
    .related {
        .listView.offices {
            --card-width: 100%;
            --card-gap: 0 2rem;

            .cardView.linkOnly:after {
                content: "|";
                margin: 0 0.5rem;
            }

            &:last-child {
                .cardView.linkOnly:after {
                    content: none;
                }
            }
        }

        .listView.teammembers.assistant {
            --card-width: 100%;
            --card-gap: 0 2rem;
        }
    }



    .wrapper {
        .viewAllMoreWrapper {
            text-align: left;
        }
    }

    .viewMoreOuter {
        margin: 0rem auto 3rem;
        text-align: center;
    }
    /* #endregion */
    /****************************************************************************************************/
    /****************************************************************************************************/
    /* #region Detail Views (shared) */
    /****************************************************************************************************/
    .detailView {


        h2 {
            text-align: center;
            position: relative;

            &:after {
                content: none;
            }

            &:before {
                content: "";
                height: 4px;
                width: 3rem;
                background-color: $tertiaryColour;
                left: 0;
                bottom: -1rem;
                transition: 0.2s ease-in 0.4s;
                display: inline-block;
                margin-right: -3rem;
                margin-bottom: -1.25rem;
            }
            /*  &:first-letter {
            border-bottom: solid 3px $tertiaryColour;
        }*/
        }

        .overview {
            margin-top: 0;
            margin-bottom: 2rem;

            .readMoreButtonWrapper {
                margin-top: 2rem;
                display: flex;
                align-items: center;
                justify-content: center;

                .toggleReadMore {
                    margin: 0 auto;
                }
            }

            .cmsContent {
                &:has(.testimonialsWrapper) {
                    display: grid;
                    grid-template-columns: 100%;
                }
            }
        }
        /* Social */
        div.socialAndSharing {
            display: flex;
            @include grid(auto, 1rem, "ul");
            padding: 1rem 0;

            ul.socialLinks {
                font-size: 1.8rem;

                li {
                    align-items: flex-end;
                }
            }

            ul.sharingLinks {
                font-size: 1.8rem;

                li {
                    align-items: flex-end;
                }
            }
        }

        .sidebarGrid {
            display: flex;
            justify-content: flex-start;
            align-content: flex-start;

            .mainContent {
                width: calc(100% - 350px);

                > :first-child {
                    margin-top: 2rem;
                }

                > :last-child {
                    margin-bottom: 4rem;
                }
            }

            .sideContent {
                width: 350px;
                padding-left: 2rem;

                .sideContentInner {
                    > :first-child {
                        margin-top: 2rem;
                    }

                    > :last-child {
                        margin-bottom: 4rem;
                    }
                }
            }

            @media only screen and (max-width : $S_Max) {
                flex-direction: column-reverse;

                .sideContent {
                    width: 100%;
                }
            }
        }
    }
    /* #endregion */
    /****************************************************************************************************/
    /****************************************************************************************************/
    /* #region Sticky Sidebar (Scroll Nav) */
    /****************************************************************************************************/
    // the sticky scroll links inside the sideContent
    .sticky-wrapper {
        position: relative;
    }

    .stickySidebar {
        padding: 0;
        position: relative;
        height: calc(100vh - 300px);
        overflow: auto;
        display: flex;
        width: 100%;
        justify-content: flex-start;

        &.stuck {
            position: fixed;
            top: 250px; // needs to match the offset value in the Waypoints/Sticky configuration
            z-index: 10;
        }

        ul.scrollingSideBar {
            padding: 1.5rem;
            width: 100%;
            background-color: rgba(white, 0.8);
            box-shadow: $boxShadow;

            li {
                position: relative;
                padding: 0.4rem 0;

                a {
                    padding: 0 1.5rem 0 0;
                    font-size: 1rem;
                    color: $offBlack;
                    text-decoration: none;
                    display: block;
                    width: 100%;

                    &:focus,
                    &:hover,
                    &:active {
                        color: $offBlack;
                        padding: 0 0 0 1.5rem;
                        font-weight: $fontWeightSemiBold;
                        transition: $transition;

                        &:before {
                            content: '';
                            position: absolute;
                            width: 0.4rem;
                            height: 0.4rem;
                            top: 50%;
                            left: 0%;
                            transform: rotate(180deg) translateX(-50%) translateY(50%);
                            background-size: 100% auto;
                            background-position: center;
                            background-image: url(/CFImages/Icons/chevron-right-secondary.svg);
                        }
                    }
                }
            }
        }
    }
    /* #endregion */
    /****************************************************************************************************/

    .cardView {
        width: 100%;
        height: 100%;

        .innerContent {
            height: 100%;

            .summary {
                margin-bottom: 3rem;
            }
        }
    }
    /****************************************************************************************************/
    /* #region Team - Cards and Detail View  */
    /****************************************************************************************************/
    .audioButton {
        padding: 0rem;
    }

    .audioPlayerWrapper {
        .playAudio {
            border: none;
            border-radius: 3px;
            cursor: pointer;
            margin: 0;
            padding: 0;
            color: #000 !important;
            display: inline;
            font-size: 1.3rem;
            background-color: transparent !important;

            &:hover, &:active {
                opacity: .8;
            }
        }
    }
    /* Team Card View */
    .cardView.teammember {
        /* image wrapper  */
        border-bottom: solid 1px $tertiaryColour;
        padding-bottom: 1rem;
        height: 100%;

        .itemImage {
            position: relative;

            background-color:$greyXLight;
            .greyWrapper & {
                filter: drop-shadow(0px 10px 8px #CCC);
            }

            img {
                object-fit: cover;
                height: 100%;
                width: 100%;
                aspect-ratio: $aspectRatio;
                object-position: 50% 10%;
            }

            .hoverContent {
                @include hugParent;
                padding: 1.5rem;
                opacity: 0;
                z-index: 2;
                background: rgba($primaryColour, .7);
                transition: $transition;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;

                span.shortBio {
                    display: block;
                    font-size: 0.8rem;

                    p {
                        font-size: 0.8rem;
                    }
                }

                .button {
                }
            }

            &:hover {
                .hoverContent {
                    opacity: 1;
                }
            }
        }
        /* content wrapper  */
        .itemContent {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            padding: 0 .5rem;

            .k-d-flex {
                justify-content: space-between;
                padding-bottom: 1rem;

                a {
                    color: $tertiaryColour;

                    .notDark & {
                        color: $primaryColour;
                    }
                }
            }

            .nameDesignationsPronunciation {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                //align-items: center;
                justify-content: flex-start;
                margin: 1rem 0 0 0;
                padding: 0.25rem 0;
                /* title */
                h3 {
                    font-size: 1.4rem;
                    margin: 0;

                    a {
                        color: #fff;
                        text-decoration: none;

                        .notDark & {
                            color: $primaryColour;
                        }
                    }
                }
                /* designations */
                .designations {
                    color: $tertiaryColour;
                    display: block;
                    margin: 0;
                }
            }
            /* audio */
            .audioPlayerWrapper {
                .playAudio {
                    color: $tertiaryColour !important;
                    font-size: 1.1rem;
                }
            }
            /* position */
            .position {
                color: $tertiaryColour;

                .notDark & {
                    color: $secondaryColour;
                }

                display: block;
                margin-bottom: 1rem;
                line-height:1.1;
            }
            /* email & phone etc */
            .contactInfo {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                margin-bottom: 1rem;

                li {
                    font-size: 1rem;

                    .prefixLabel {
                        font-weight: $fontWeightBold;
                        color: $tertiaryColour;
                        display: inline-block;
                        margin-right: 0.3em;

                        .notDark & {
                            color: $primaryColour;
                        }
                    }

                    a {
                        text-decoration: none;
                        color: #fff;

                        .greyWrapper & {
                            color: $primaryColour;
                        }

                        .notDark & {
                            color: $primaryColour;
                        }

                        &:focus,
                        &:hover {
                            text-decoration: underline;
                        }

                        &:active {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
        // in case no gender pronouns
        .spaceFiller {
            height: 1.5rem;
        }
    }

.detailView.teammember {
    .bioTopContent {
        position: relative;
        width: 100%;
        display: block;
        color: #fff;
        overflow: hidden;

        .bioMobileButtons {
            visibility: hidden;
            position: absolute;
            top: 2rem;
            right: 1rem;
            z-index: 5;

            @media only screen and (max-width: $S_Mid) {
                visibility: visible;

                a {
                    background-color: $primaryColour;
                    border-radius: 100%;
                    height: 2rem;
                    width: 2rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: .5rem;
                    position: relative;
                    cursor: pointer;
                    transition: $transition;

                    &:before {
                        content: none;
                    }

                    svg {
                        color: #fff;
                    }

                    &:hover {
                        transform: scale(1.2);
                    }
                }
            }
        }

        .bioPhotoWrapper {
            flex: auto;
            display: flex;
            flex-direction: row-reverse;
            width: 100%;
            flex-basis: auto;
            //background: radial-gradient(circle at 72% 44%, $greyXXXLight 0%, $greyXLight 100%);
            position: relative;
            background-color: $greyXXLight;

            .imgWrapper {
                // width: 50%;
                width: 70%;
                max-height: 700px;

                img {
                    aspect-ratio: 7/5;
                    mask-image: linear-gradient(to right, transparent 0%, $greyXXLight 15%);
                    object-position: 0 20%;
                    //-webkit-mask-image: linear-gradient(to right, transparent 0%, black 15%);
                }
            }
        }

        .bioContact {
            position: absolute;
            top: 0;
            left: 5rem;
            width: 40%;
            @include forceColour($primaryColour);
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: center;
            font-size: 90%;

            .nameDesignationsPronunciation {
                display: flex;
                flex-wrap: wrap;
                //align-items: center;
                justify-content: flex-start;
                align-items: center;
                margin: 1rem 0 0 0;
                padding: 0.25rem 0;
                /* title */
                h1 {
                    // name
                    position: relative;
                    font-size: 4em;
                    margin: .25rem 0;

                    span {
                        font-size: 60%;
                        font-weight: 300;
                    }

                    &:after {
                        content: none;
                    }
                }
                /* designations */
                .designations {
                    color: $tertiaryColour;
                    display: block;
                    margin: 0;
                }
                /* audio */
                .audioFile {
                    display: inline-block;
                    margin: 0;
                    padding-left: 1rem;
                    font-size: 1.4rem;

                    a:hover {
                        color: $tertiaryColour;
                    }
                }
            }


            h3 {
                margin: 0 0 .5rem;
                color: $secondaryColour;
                font-family: $primaryFont;
            }

            .calltobars {
                display: flex;
                justify-content: space-between;
                border: solid 1px $grey;
                padding: .5rem 0;
                margin-bottom: .5rem;
                border-left: none;
                border-right: none;

                span {
                    text-transform: uppercase;
                    font-weight: 700;
                }

                ul {
                    display: flex;
                    justify-content: flex-end;

                    li {
                        padding-left: .25rem;
                    }
                }
            }


            .contactInfo {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin: 1.5rem 0 .5rem;

                .prefixLabel {
                    color: $secondaryColour;
                    font-weight: 700;
                }
            }

            .professionalCorporation {
                padding-right: 2rem;
                margin-top: 1rem;
                line-height: 1;
            }

            .printAndShare {
                font-size: 1.5rem;

                a {
                    margin-right: 1rem;

                    &:hover {
                        color: $tertiaryColour;
                        cursor: pointer;
                    }
                }
            }

            ul.socialLinks {
                justify-content: flex-start;

                li {
                    display: inline-block;
                    font-size: 1rem;
                    margin-left: 0;
                    margin-right: 2rem;

                    a:hover {
                        color: $primaryColour;
                    }
                }
            }
        }
    }
}
    /* #endregion */
    /****************************************************************************************************/
    /****************************************************************************************************/
    /* #region QR Codes and Online Business Card */
    /****************************************************************************************************/
    /* The QRCode Image itself */
    span.QRCodeWrapper {
        display: flex;
        align-items: center;
        width: fit-content;
        flex-direction: column;
        border: 1px solid black;
        background: #fff;
        margin: 1rem 0;
        padding: 0.5rem;
        //optional wrapper around img and text
        a {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        img {
            width: 100px;
        }
        //Optional text below qr code
        span.QRCodeLabel {
            font-size: 0.8rem;
            //@include button;
        }
    }
    /* Modal version */
    #QRModal {
        display: none;
    }

    #QRModalMobile {
        display: none;
    }

    #QRModal {
        max-width: 100%;
        background-color: white;
        padding: 1rem;
        border: $border;
        border-radius: 1rem;

        span.QRCodeWrapper {
            border: none;
            margin: 0;
            padding: 0;

            img {
                width: 280px;
            }
        }
    }
    /* THE BIO CARD (Online Business Card) */

    .bioCardTemplate {
        a {
            text-decoration: none;
        }

        header .bioCardHeader {
            .container.narrow {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                gap: 0.5rem;
            }
        }

        main .bd {
            .detailView.teammember.bioCard {
                --busCardImageWidth: 140px;

                .busCard {
                    position: relative;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-bottom: 2rem;
                    padding-top: 4rem;

                    .busCardHeader {
                        display: flex;
                        justify-content: space-around;
                        width: 100%;
                        position: relative;
                        z-index: 1;

                        .busCardLogo {
                            display: flex;
                            justify-content: flex-end;
                            height: auto;
                            width: var(--busCardImageWidth);
                            background-image: url(/CFImages/logo.png);
                            background-repeat: no-repeat;
                            background-size: contain;
                            background-position: top right;
                        }

                        .busCardImage {
                            width: var(--busCardImageWidth);
                            border: 3px solid #fff;
                            border-radius: 100%;
                            overflow: hidden;

                            .imgWrapper {
                                height: 135px;
                            }
                        }
                    }



                    .busCardContent {
                        background-color: #fff;
                        padding: 6rem 3rem 3rem;
                        width: calc(100% - #{var(--busCardImageWidth) / 2});
                        margin-top: calc(#{var(--busCardImageWidth) / 2 } * -1);
                        position: relative;
                        z-index: 0;

                        h3 {
                            margin: 0;
                            font-size: 1rem;
                        }

                        .fullTitle {
                            .nameAndPronunciation {
                                margin-top: 1rem;

                                h1 {
                                    font-size: 1.5rem;
                                    font-weight: $fontWeightBold;
                                    color: $primaryColour;
                                    margin: 0;

                                    &:after {
                                        content: none;
                                    }
                                }

                                span.audioFile {
                                    padding-left: 1rem;
                                }
                            }

                            .designationsAndPronouns {
                                span.designations {
                                    font-size: 1rem;
                                }

                                .pronounsWrapper {
                                    ul {
                                        li {
                                            font-size: 1rem;
                                        }
                                    }
                                }
                            }
                        }
                        /* Call To Action Links (View Full Bio, Download Contact) */
                        .callToActionWrapper {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            gap: 1rem;
                            justify-content: space-between;
                            margin-top: 1.5rem;

                            .socialLinks {
                                font-size: 1.5rem;
                                display: flex;
                                gap: 1rem;
                                align-items: center;
                            }

                            .button {
                                margin: 0;
                            }
                        }

                        .contactInfo {
                            border-top: solid 1px $greyXLight;
                            padding-top: 2rem;
                            margin: 2rem 0;

                            li {
                                display: flex;
                                gap: 1rem;
                                margin-bottom: 1rem;

                                .icon {
                                    background-color: $primaryColour;
                                    border-radius: 100%;
                                    height: 3rem;
                                    width: 3rem;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;

                                    &:before {
                                        content: none;
                                    }

                                    svg {
                                        color: #fff;
                                    }
                                }

                                .content {
                                    align-self: center;
                                }
                            }
                        }
                    }
                }

                .additionalInformation {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 2rem;
                    /* ShortBio */
                    div.shortBio {
                        width: 80%;
                        text-align: center;
                    }
                    /* Languages Spoken*/
                    .languagesSpoken {
                        display: flex;
                        flex-direction: row;
                        gap: 0.25rem;

                        p {
                            font-weight: bold;
                            margin: 0;
                            text-align: right;
                        }

                        span.languages {
                        }
                    }

                    .related.wrapper {
                        border: $border;
                        border-radius: 1rem;
                        box-shadow: $boxShadow;
                        padding: 2rem;
                        width: 100%;

                        h2.listViewTitle {
                            margin: 0 0 0.5rem 0;
                            font-size: 1.2rem;
                            text-transform: uppercase;
                            color: $bodyFontColour;
                            font-weight: $fontWeightBold;
                        }
                    }
                    /* Location */
                    .related.officesWrapper {
                    }
                    /* Assistants */
                    .related.teammembersWrapper.assistant {
                        .cardView.teammember {
                            .itemContent {
                                padding: 0;
                            }
                        }
                    }
                    /* Social Media */
                    .socialLinks {
                        font-size: 1.5rem;
                        display: flex;
                        gap: 1.5rem;
                    }
                }

                span.QRCodeWrapper {
                    img {
                        width: 150px;
                    }
                }
            }

            @media only screen and (max-width: $S_Mid) {
                .detailView.teammember.bioCard {
                    .busCard {
                        justify-content: flex-start;
                        align-items: center;
                        flex-direction: column;

                        .busCardImage {
                            position: relative;
                            left: initial;
                            width: var(--busCardImageWidth);
                        }

                        .busCardContent {
                            margin-top: calc((#{var(--busCardImageWidth) / 2}) * -1);
                            padding: calc(2rem + #{var(--busCardImageWidth) / 2}) 2rem 2rem 2rem;
                            width: 100%;

                            .busCardLogo {
                                background-position: top left;
                            }
                        }
                    }
                }
            }
        }

        footer .bioCardFooter {
            text-align: center;
        }
    }
    /* #endregion */
    /****************************************************************************************************/
    /****************************************************************************************************/
    /* #region Expertises + Sectors - Cards and Detail View  */
    /****************************************************************************************************/
    /* Expertise: CardView (Shared) */



    .cardView.expertise .innerContent .itemImage .hoverWrapper .hoverContent {
        padding: 1.5rem 2.5rem;
        font-size: .8rem;
        text-align: center
    }

    .cardView.expertise .innerContent .itemImage .hoverWrapper .hoverContent h4 {
        margin-top: 1rem
    }







    .cardView.expertiseandsector {
        border-bottom: solid 1px $tertiaryColour;
        display: flex;
        justify-content: center;
        align-items: center;

        h3 {
            margin: 0;
            padding: 0;
            text-decoration: none;
            /* word-break: break-word; // added at morgan's request to prevent awkward overlapping with long expertise titles
            hyphens: auto;*/
            font-size: 1.6rem;
            text-align: center;

            &:after {
                width: 3.8rem;
            }

            a {
                color: inherit;
                text-decoration: none;
                padding: 3.5rem 1rem;
                display: block;

                &:focus,
                &:hover {
                    color: $secondaryColour;
                }

                &:active {
                    color: $secondaryColourDark;
                }
            }
        }

        .icon {
            position: absolute;
            bottom: 0.3rem;
            right: 0.3rem;
            font-size: 1.25rem;
            transform: rotate(45deg);
            transition: $transition;
        }

        .hoverContent {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: $tertiaryColour;
            opacity: 1;
            overflow: hidden;
            width: 100%;
            height: 0;
            transition: $transition;

            a {
                color: #fff;
            }

            p {
                text-align: center;
                margin: 0.25rem 0 0 0;
                font-size: 1.2rem;
            }

            ul {
                width: 100%;
                display: inline-block;
                text-align: center;
                margin-bottom: 1rem;
                padding: 1rem;
            }

            ul li {
                font-size: .85rem
            }

            @media only screen and (max-width: 600px) {
                ul li {
                    font-size: .65rem;
                    font-size: 1rem
                }
            }
        }

        &:hover {
            background-color: $tertiaryColour;

            .hoverContent {
                height: 90%
            }

            h3 {
                color: #fff;

                a {
                    color: #fff;
                }
            }

            .icon {
                bottom: 88%;
                transition: $transition;
                transform: rotate(-45deg);
            }
        }
    }
    /* expertise tree view */
    .expertisesWrapper.treeViewWrapper {
        ul.level1 {
            visibility: hidden;
        }

        .k-treeview ul.level1 {
            visibility: visible;
            margin-bottom: 3rem;
            border-bottom: solid 1px $tertiaryColour;
        }

        .k-treeview {
            overflow: visible;

            .k-item {
                @include headingStyles;
                font-size: 1.2rem;

                .k-link,
                .k-in {
                    font-size: 2.3rem;
                    padding: 1.4rem 2rem;
                    width: 100%;
                    color: #fff;
                    white-space: normal;

                    &:focus,
                    &:hover,
                    &.k-state-focused {
                        color: $tertiaryColour;
                        background-color: transparent;
                    }

                    &.k-state-selected {
                        color: $tertiaryColour;
                        background-color: transparent;
                    }

                    &:active {
                        color: $tertiaryColour;
                    }
                }

                .k-in:not(.k-link) {
                    // prevent outlines and highlighting for non-links
                    color: $greyLight;
                    display: none !important; // completely hide non-linking children

                    &:focus,
                    &:hover {
                        color: $greyLight;
                    }

                    &:focus,
                    &:active,
                    &.k-state-focused,
                    &.k-state-selected {
                        outline: none !important;
                        box-shadow: none !important;
                    }
                }

                .k-icon {
                    width: 2rem;
                    height: 2rem;
                    border: solid 1px #fff;
                    right: 2%;

                    &:before {
                        top: 50%;
                        left: 50%;
                        transform: translateX(-50%) translateY(-50%);
                        width: 1rem;
                        height: 1rem;
                        background-size: 100% auto;
                        background-position: center;
                    }

                    &.k-i-expand {
                        &:before {
                            background-image: url(/CFImages/Icons/plus-white.svg);
                        }
                    }

                    &.k-i-collapse {
                        &:before {
                            background-image: url(/CFImages/Icons/minus-white.svg);
                        }
                    }
                }
            }

            .k-item + .k-item {
                border-top: solid 1px $tertiaryColour;
            }
            /* first level */
            /*.level1 {
            > .k-item {
                margin-bottom: 0.8rem;
            }
        }*/
            /* second level */
            .level2 {
                margin: 0;
                background-color: $primaryColour;
                padding: 0 1rem;

                .k-item {


                    .k-link,
                    .k-in {
                        padding: 1.2rem 0;
                        padding-left: 2rem;
                        font-size: 1.8rem;

                        &:after {
                            display: none;
                        }
                    }

                    .k-icon {
                        left: 0rem;
                    }

                    &:first-child {
                        .k-link {
                            /*border-top: $border;*/
                            border-top: none;
                        }
                    }
                }
            }
            /* third level */
            .level3 {
                padding-left: 2.4rem;

                .k-item {
                    font-size: 0.8rem;

                    &:first-child {

                        .k-link,
                        .k-in {
                            border-top: none;
                        }
                    }
                }
            }
        }
    }

    .simple.expertises.aboutWrapper {
        h2 {
            color: #fff !important;
        }

        ul.listView {
            --card-width: 28rem;

            .cardView {
                justify-content: flex-start;

                a {
                    color: #fff;
                    padding: .5rem;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }

    .detailView.expertise {
    }
    /* #endregion */
    /****************************************************************************************************/
    /****************************************************************************************************/
    /* #region Insights (shared styles) - Cards and Detail View  */
    /****************************************************************************************************/
    /* .publications .blogitems .newsitems .eventitems .cases etc... */
    /* Insight CardView */
    .cardView.insight {
        margin: 0;
        position: relative;
        height: 100%;
        width: 100%;
        padding-right: 2rem;
        /* &:hover {
        background-color: $primaryColour;
    }*/
        .itemImage {
            .imgWrapper {
                img {
                    aspect-ratio: 2;
                    margin-bottom: 2rem;
                }
            }
        }

        .contentTypeLabels {
            display: flex;

            a {
                font-size: 0.8rem;
                text-decoration: none;
                font-optical-sizing: 0.7rem;
                color: $primaryColour;

                .primaryWrapper & {
                    color: $tertiaryColour;
                }
            }
        }

        .meta {
            margin-top: 0.6rem;
            text-transform: none;
            color: $primaryColour;

            .primaryWrapper & {
                color: $tertiaryColour;
            }
        }

        .dates {
            text-transform: inherit;
            color: $primaryColour;

            .primaryWrapper & {
                color: $tertiaryColour;
            }

            time {
                text-transform: inherit;
            }
        }

        h3 {
            margin-top: 0.6rem;
            margin-bottom: 1rem;
            font-size: 2.2rem;

            a {
                text-decoration: none;
                color: $primaryColour;

                .primaryWrapper & {
                    color: #fff;
                }

                &:hover {
                    color: $tertiaryColour;
                }
            }
        }

        .cmsContent {
            font-size: 0.9rem;
            line-height: $lineHeight;
            font-weight: $fontWeightLight;
        }

        .authorTeam {
            margin-bottom: 1rem;

            a {
                color: $primaryColour;

                .primaryWrapper & {
                    color: $tertiaryColour;
                }
            }
        }
    }

    .listView.insights:not(.related) {
        .cardView.insight {
            .innerContent {
                padding: 1rem 1.5rem 3rem;

                a {
                    color: $tertiaryColour;
                }

                .dates {
                    color: $tertiaryColour;
                }

                h3 {
                    a {
                        color: #fff;
                    }
                }

                &:hover {
                    background-color: $primaryColour;
                }
            }

            border-bottom: solid 1px darken($secondaryColour, 5%);
        }
    }
    /* Insight DetailView */
    .detailView.insight {
        .topperSection {

            .topperContent {
                .topperTitle {
                    h1 {
                        margin-top: 0;
                    }
                }
            }
        }
    }
    /* blog detail only */
    .detailView.insight.blogitem {
    }
    /* Home Page Featured Insights (with slider) */
    .homePageFeatured {
        margin: 5rem 0;

        .homePageFeaturedGrid {
            display: flex;
            flex-wrap: nowrap;
            margin: 0;

            .featuredItems {
                margin: 0;
                /*border-right: 1px solid $primaryColour;*/
                border-right: $border;
                padding-right: 2rem;

                .cardView {
                    h3 {
                        font-size: 2.5rem;
                        margin: 0.6rem 0 1rem;
                    }
                }
            }

            .recentItems {
                margin: 0;

                .splide {
                    height: 100%;
                    padding-left: 2rem;

                    .splide__list {
                        @include gutter(2rem, ".splide__slide");

                        .splide__slide {
                            display: flex;
                            flex-direction: column;

                            .cardView {
                                height: auto;
                            }
                        }
                    }
                }
            }
        }

        .viewAllMoreWrapper {
            display: flex;
            justify-content: center;
            margin-top: 5rem;
        }

        .splide__pagination {
            display: none;
        }

        .splide__arrows {
            position: absolute;
            left: 0;
            top: calc(100% + 1.4rem);
            display: flex;

            .splide__arrow {
                color: $primaryColour;
                position: relative;
                inset: initial;
            }
        }

        &.hasFeaturedItems {
            .homePageFeaturedGrid {
                @include grid(2, 0, "div.col");
            }

            .splide__arrows {
                transform: translateX(calc(-50%));
                /*transform: translateX(calc(-50% - 1px));*/
                .splide__arrow {
                }
            }
        }
    }
    /* #endregion */
    /****************************************************************************************************/
    /****************************************************************************************************/
    /* #region News - Cards and Detail View */
    /****************************************************************************************************/
    /* News CardView */
    .cardView.insights.newsitem {
        padding-bottom: 2rem;

        h3 {
            margin: 0;
            font-size: 1.2rem;

            a {
                color: inherit;

                &:hover,
                &:focus {
                    color: $secondaryColour;
                }

                &:active {
                    color: $secondaryColourDark;
                }
            }
        }

        .summary {
            margin-top: 1rem;
        }
    }
    /* #endregion */
    /****************************************************************************************************/
    /****************************************************************************************************/
    /* #region Events - Cards and Detail View */
    /****************************************************************************************************/
    .cardView.event {
        .innerContent {
            .meta {
                text-transform: uppercase;

                .dates {
                }
            }
        }
    }

    .detailView.event {
    }
    /* #endregion */
    /****************************************************************************************************/
    /****************************************************************************************************/
    /* #region Blog Items - Cards and Detail View */
    /****************************************************************************************************/
    .detailView.blogitem {
        .contentSidebarWrapper {
            margin-top: -3rem;

            .scrollingSideBar {
            }

            .readMoreContent {
                padding-top: 4.4rem;
            }
        }

        .primaryImage {
            .bgImgWrapper {
                @include aspectRatioBox(16 4);
            }
        }
    }
    /* #endregion */
    /****************************************************************************************************/
    /****************************************************************************************************/
    /* #region Cases - Cards and Detail View */
    /****************************************************************************************************/
    .cardView.case {
    }

    .detailView.case {
        // remove the gap between expertise and blockquote (if there are no people)
        .expertisesWrapper + .bigBlockQuote {
            margin-top: 0 !important;
        }
    }
    /* #endregion */
    /****************************************************************************************************/
    /****************************************************************************************************/
    /* #region Topics */
    /****************************************************************************************************/
    .cardView.topic {
        border-bottom: solid 1px $tertiaryColour;
    }

    .detailView.topic {
        background-color: $primaryColour;

        .jumpMenuWrapper {
            filter: none;
        }
    }
    /*#endregion */
    /****************************************************************************************************/
    /****************************************************************************************************/
    /* #region Rankings - Cards and Detail View  */
    /****************************************************************************************************/
    /* .rankings */
    /* Ranking CardView */
    .cardView.ranking {
        margin: 0;
        position: relative;
        padding-top: 2rem;

        &:before {
            content: "T|M";
            position: absolute;
            background-color: $primaryColour;
            font-family: $secondaryFont;
            font-size: 1.8rem;
            padding: 1rem;
            left: 50%;
            top: -.4rem;
            transform: translate(-50%);
            color: #fff;
        }

        .innerContent {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            padding: 3rem 1.5rem;
            border: solid 1px $tertiaryColour;

            span {
                display: block;
                margin: 0.2rem;
            }

            span.rankingPublisher {
                color: #fff;
                font-size: 1.4rem;
                text-align: center;
                position: relative;
                padding-bottom: 1rem;

                &:after {
                    content: '';
                    background-color: $tertiaryColour;
                    height: 3px;
                    width: 2rem;
                    left: 50%;
                    transform: translatex(-50%);
                    bottom: 0;
                    position: absolute;
                }
            }

            span.rankingAccolade {
                color: #fff;
                font-size: 1.2rem;
                text-align: center;
            }
            /* optional item */
            span.rankingLocation {
                color: #fff;
                font-size: 0.7rem;
                text-transform: uppercase;
            }

            span.yearAwarded {
                color: #fff;
                font-size: 1.2rem;
            }

            .authorTeam {
                color: $tertiaryColour;
                font-weight: 500;
                margin: .25rem 0;

                a {
                    color: $tertiaryColour;
                }
            }

            .notDark & {
                background-color: $greyXXLight;
                border: solid 1px $primaryColour;

                span.rankingPublisher {
                    color: $primaryColour;
                }

                span.rankingAccolade {
                    color: $primaryColour;
                }
                /* optional item */
                span.rankingLocation {
                    color: $grey;
                }

                .teammember {
                    color: $primaryColour;
                }

                span.yearAwarded {
                    color: $primaryColour;
                }
            }
        }
    }
    /*#endregion */
    /****************************************************************************************************/
    /****************************************************************************************************/
    /* #region Testimonials - Cards and Detail View  */
    /****************************************************************************************************/

    .testimonialsWrapper {
        background-color: $secondaryColour;
        font-style: italic;
        padding: 2rem 3rem;
        box-shadow: $boxShadow;
        grid-row: 3;
        margin: 1rem 0 2rem;

        ul {
            li {
                margin: 0;
                padding: 0;

                &:after {
                    content: none;
                }
            }
        }

        &.splide.wrapper .sliderFooter .splide__arrows {
            .splide__arrow {
                border-color: #fff;
                color: #fff;
            }
        }
    }
    /* Testimonial CardView */
    .cardView.testimonial {
        blockquote {
            margin: 0;
            text-align: center;
            /*@extend %doubleQuoteBefore;*/

            cite {
                display: block;
                margin-top: 1.4rem;
                font-size: 1rem;
            }
        }
    }
    /*#endregion */
    /****************************************************************************************************/
    /****************************************************************************************************/
    /* #region Filtered Content Items */
    /****************************************************************************************************/
    .fieldGroups {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 1.2rem;

        > fieldset {
            flex: 1 1 0;
            margin: 0;
            padding: 0;
            border: none;

            legend {
                @include sr-only;
            }
        }
        // Dropdowns
        .k-dropdown {
            width: 100%;

            &:hover,
            &.k-state-border-down {
                .k-dropdown-wrap {
                    border-color: $greyLight;
                }
            }

            .k-dropdown-wrap {
                border: none;
                border-bottom: $border;
                border-radius: 0;
                box-shadow: none;
                background: none;
                transition: $transition;

                .k-input {
                    padding: 1.6rem 1.2rem;
                    font-size: 1rem;
                    color: #fff;
                    user-select: none;
                }

                .k-select {
                    padding-right: 1.2rem;

                    .k-icon {
                        width: 0.6rem;
                        height: 0.6rem;

                        &:before {
                            background-image: url(/CFImages/Icons/caret-down-solid.svg);
                            background-size: 0.6rem auto;
                        }
                    }
                }
            }
        }
        // Checkboxes
        &.checkboxes {
            flex-wrap: wrap;

            fieldset {
                legend {
                    width: 100%;
                    height: auto;
                    position: relative;
                    margin: 0;
                    padding: 0;
                    overflow: initial;
                }

                ul.grid.checkboxes {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;

                    li.field.col {
                        flex: 0;
                        font-size: 0.9rem;
                    }
                }
            }
        }
    }



    .k-list-container {
        border: 1px solid $greyLight;
        border-top-width: 0;
    }

    .k-list-optionlabel,
    .k-list .k-item {
        padding: 0.8rem 0.6rem;
        color: #fff;

        &.k-state-selected {
            background-color: $secondaryColour;

            &:hover {
                background-color: darken($secondaryColour, 5%);
            }
        }
    }
    /* Filters */
    .filtersContainer {
        #contentFilters-1 {
            position: sticky;
            top: 160px;
        }

        fieldset {
            legend {
                @include sr-only;
            }
        }

        h2 {
            margin-top: 0;
            text-align: left;

            &:before {
                content: none;
            }
        }

        .resetFilters {
            margin-top: 1rem !important;
        }


        .h3 {
            @include headingStyles;
            margin: 2.4rem 0 1.38rem;
            padding: 0;
            display: block;
            font-size: 1.824rem;

            p {
                font-size: inherit;
                font-family: inherit;
            }
        }
    }
    /* Keyword Search */
    .keywordSearchWrapper {
        margin-bottom: 1.4rem;
        position: relative;

        input[type=search] {
            border: $border;
            box-sizing: border-box;
            height: 3.4rem;
            background-color: transparent;
            color: #fff;

            &:focus,
            &:active,
            &:hover {
                outline: none !important;
            }
        }


        input[type=search]:not(:placeholder-shown) ~ .buttons {
            .clearSearch {
                display: block;
                opacity: 1;
            }
        }

        .buttons {
            position: absolute;
            right: 0;
            top: 0;
            left: auto;
            bottom: 1px;
            display: flex;
            align-items: stretch;

            .button {
                border: none;
                padding: 0;
                background-color: transparent;
                width: 3rem;
                margin: 0;
            }
        }
    }
    /* Search by initial (alphabet) */
    .searchByInitialContainer {
        margin: 2rem 0;

        > .grid {
            display: grid !important;
            gap: 0.4em;
            grid-template-columns: repeat(13, minmax(0, 1fr));

            > .col {
                aspect-ratio: 1/1;
            }
        }

        a.initialFilter {
            cursor: pointer;
            text-decoration: none;
            color: #fff;
            font-family: $secondaryFont;
            font-size: 1.5rem;

            &.selected,
            &:hover {
                color: $tertiaryColour;
            }
        }
    }
    /* Filtered Content Wrapper */
    .filteredContent {
        .filteredContentGrid {
            display: grid;
            grid-template-columns: 30% 1fr;
            gap: 5rem;
            margin-bottom: 3rem;
        }

        .activeFilterGroup {

            .activeFilterTag {
                border: $border;
                padding: 0.4rem 1rem;
                cursor: pointer;
                position: relative;
                padding-right: 2.4rem;
                margin-top: 0.6rem;
                user-select: none;
                margin-right: 1rem;
                color: #fff;
                display: inline-block;

                &:not(:last-of-type) {
                    margin-right: 0.6rem;
                }

                &:after {
                    top: 50%;
                    content: ' ';
                    right: 0.8rem;
                    color: $secondaryColour;
                    background-image: url(/CFImages/Icons/plus.svg);
                    background-size: 0.6rem auto;
                    background-position: center;
                    transform: translateX(-50%) translateY(-50%) rotate(45deg);
                    transform-origin: center;
                    width: 0.6rem;
                    height: 0.6rem;
                    position: absolute;
                }

                &:hover,
                &:focus {
                    border-color: $greyLight;
                    //background-color: $greyXXXLight;
                }

                &:active {
                    border-color: $greyLight;
                    //background-color: darken($greyXXXLight, 5%);
                }
            }
        }

        .loadMoreContainer {
            margin: 4rem 0 0;

            button {
                .icon {
                    font-size: 0.8rem;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 0.6rem;
                    margin-right: 0.6rem;
                }
            }
        }

        .permanentLinkField {
            display: flex;

            button {
                padding: 0.6rem 1rem;
                margin: 0;

                svg {
                    margin: 0;
                }
            }
        }
    }
    /* Active filters tags/pills */
    #filtersDisplayContainer {
    }

    .filtersPopupWrapper {
        width: 100%;
        left: 0 !important;

        .advancedFiltersContainer {
            margin: 0 auto;
            display: block !important;
        }
    }

    #loadingIndicator {
        height: 200px;
        display: none;
        margin-top: 2rem;

        svg {
            margin: 0 auto;
        }

        &.loading {
            content: "loading";
            line-height: 200px;
            text-align: center;
            display: block;
        }
    }

    #loadingCounter {
        margin: 2rem 0 2rem;
        color: #fff;
        font-style: italic;
    }

    #filteredContentResult {
        li.noResults {
            color: #fff;
            width: 100% !important;
            max-width: 100% !important;
            margin: 0;
            flex: initial;
            display: flex;
            flex-direction: column;

            .message {
                font-style: italic;
                font-size: 1.5rem;
            }

            .button {
            }
        }
    }
    /* #endregion */
    /****************************************************************************************************/
    /****************************************************************************************************/
    /*#region Popups / Modals (SHARED) */
    /****************************************************************************************************/
    .popup_background {
    }

    .popup_wrapper {

        .button.close {
            top: -2rem;
            right: 0;
            width: 2rem;
            height: 2rem;
            position: absolute;
            border: none;
            padding: 0;
            border-radius: 0;
            background-color: white;
            border: $border;

            &:before {
                background-image: url(/CFImages/Icons/plus.svg);
                background-size: 1rem auto;
                background-position: center;
                background-repeat: no-repeat;
                transform: rotate(45deg);
                transform-origin: center;
                content: ' ';
                display: inline-block;
                position: absolute;
                inset: 0;
            }

            &:focus,
            &:hover {
                background-color: transparent;

                &:before {
                    /*background-image: url(/CFImages/Icons/plus-white.svg);*/
                }
            }

            &:active {
                background-color: transparent;

                &:before {
                    /*background-image: url(/CFImages/Icons/plus-white.svg);*/
                }
            }
        }
    }
    /* #endregion */
    /****************************************************************************************************/
    /****************************************************************************************************/
    /*#region Site Search */
    /****************************************************************************************************/
    .searchHiddenWrapper {
        background-color: $secondaryColour;
        padding: 0;

        .form-inline {
            width: 100%;
        }

        .searchHidden {
            display: flex;
            align-items: center;
            justify-content: center;

            .formGroup {
                display: flex;
                width: 100%;
                // Search box
                input[type="search"] {
                    width: 100%;
                    color: #fff;
                    background-color: transparent;
                    border: 1px solid transparent;
                    padding: 0.4rem 3rem 0.4rem 1em;
                    font-size: 2rem;
                    background-repeat: no-repeat;
                    background-position: right 1em center;
                    text-overflow: ellipsis;
                    text-align: center;

                    @include placeholder {
                        opacity: 1;
                        transition: $transition;
                        text-transform: uppercase;
                        color: #fff;
                        text-transform: none;
                        text-align: center;
                    }

                    &:hover,
                    &:focus {
                        @include placeholder {
                            opacity: 0.4;
                        }
                    }
                    /* &:focus {
                    outline: 2px solid $tertiaryColour;
                }*/
                }
                // Submit button
                .sfsearchSubmit {
                    color: #fff;
                    background: transparent;
                    margin: 0 0 0 -2.5rem;
                    padding: 0.25rem 0.5rem;
                    font-size: 1.1rem;
                    border: none;

                    svg {
                        margin: 0;
                    }

                    &:hover {
                        cursor: pointer;
                        color: $tertiaryColour;
                    }
                }
            }
        }

        button.searchCollapse {
            border: none !important;
            margin-top: 0 !important;
            color: #fff;

            [class*="fa-"] {
                margin-left: 0;
            }
        }
    }
    // Search Results
    .searchSorting {
        width: 600px;
        max-width: 100%;
        padding-bottom: 2rem;
    }

    .searchboxWrapper {
        display: flex;

        button {
            margin: 0 0 0 1rem;
            color: #fff;
        }
    }

    .searchResultsWrapper {
        display: grid;
        grid-template-columns: 1fr 5fr;
        gap: 3rem;

        .cardView.searchdefault {
            padding: 2rem 3rem;
        }

        .cardView.insight {
        }

        .nonDynamic {
            padding: 2rem;

            a {
                word-break: break-all;
            }
        }

        @media only screen and (max-width: $M_Max) {
            > ul.grid {
                @include grid(2, 2rem, ".col");
            }
        }

        @media only screen and (max-width: $S_Mid) {
            > ul.grid {
                @include grid(1, 0, ".col");
            }
        }

        ul.grid .col {
            margin-bottom: 1rem;
        }
    }

    div.searchPager {
        margin: 2rem 0;
        text-align: center;

        ul.pagination {
            @include grid(auto, 1rem, "li");
            justify-content: center;

            li {
                margin-top: 1rem;
            }

            li.active {
                a {
                    color: #fff;
                }
            }
        }
    }
    /* #endregion */
    /****************************************************************************************************/
    /****************************************************************************************************/
    /* #region Global Alerts */
    /****************************************************************************************************/
    /* refer to class .dark for a lot of the styles */
    .alertsHeader {
        height: auto;
    }

    .cardView.globalalert {
        padding: 0.5rem 0;
        position: relative;
        display: flex;
        align-items: center;

        .alertIcon {
            padding-right: 1rem;

            svg {
                font-size: 1.2rem;
                color: white;
            }
        }

        .alertContent {
            // The alert content itself (style content in here)
            a.alertLabelLink {
                text-decoration: underline;
                display: block;
                margin-bottom: 0.25rem;
                font-size: 0.9rem;
            }

            .optionalContent {
                font-size: 0.8rem;

                p {
                    margin: 0;
                    font-size: 0.8rem;
                }

                a {
                    text-decoration: underline;
                }
            }
        }
    }
    /* #endregion */
    /****************************************************************************************************/
    /****************************************************************************************************/
    /* #region Accordions + FAQs */
    /****************************************************************************************************/
    .accordionGroup.k-group {
        .k-item {
            margin-bottom: 0;
            width: 100%;
            background-color: $secondaryColour;
            white-space: normal;

            .accordionTop {
                padding: .5rem 1rem .5rem 2rem;
                border-bottom: solid 1px $secondaryColourLight;

                .cmsContent {
                    color: #fff;

                    h4 {
                        margin: 1rem 0;
                        color: #fff;
                    }
                }

                p {
                    margin: 0;
                    padding: 0;
                }

                .k-in {
                    padding: 0;
                }

                .k-icon:before {
                    background-image: url(/CFImages/Icons/plus-white.svg);
                }

                .k-icon.k-i-collapse:before {
                    background-image: url(/CFImages/Icons/minus-white.svg);
                }
            }

            .k-content {
                display: block;
                background-color: $greyXXLight;
                padding: 2rem;
                white-space: normal;

                .k-in {
                    display: none;
                }
            }
        }
    }
    /* #endregion */
    /****************************************************************************************************/
    /****************************************************************************************************/
    /* #region Toggle Sections */
    /****************************************************************************************************/
    .toggleAction {
    }

    .toggleContent {
        display: none;

        &:active {
            display: block;
        }
    }
    /* #endregion */
    /****************************************************************************************************/
    /****************************************************************************************************/
    /* #region Image Galleries */
    /****************************************************************************************************/
    // Gallery slider
    .imageGallery.slider {
        //background-color: $greyXLight;
        max-width: 100%;

        .gallerySlider {
            .splide__arrows {
                opacity: 0;
            }

            &:hover {
                .splide__arrows {
                    transition: $transition;
                    opacity: 1;

                    .splide__arrow {
                    }
                }
            }

            .splide__slide {
                min-height: 600px;
                height: 70vh;
                max-height: 700px;
                max-width: 100vw;

                > a {
                    cursor: zoom-in;
                    position: absolute;
                    inset: 0;
                }

                img {
                    object-fit: scale-down;
                    object-fit: cover;
                    object-position: 50% 13%;
                    height: 100%;
                    width: 100%;
                }

                .descriptionWrapper {
                    position: absolute;
                    top: auto;
                    left: 0;
                    right: 0;
                    width: 100%;
                    bottom: 0;
                    z-index: 2;
                    color: black;
                    max-width: 100%;
                    background-color: rgba(white, 0.8);
                    /*                opacity: 0;
                    transition: $transitionQuint;*/
                    .description {
                        font-size: 0.9rem;
                        padding: 2rem;
                        display: inline-block;

                        > :last-child {
                            margin-bottom: 0;
                        }
                    }
                }
                /*            &:hover {
                .descriptionWrapper {
                    opacity: 1;
                    .description {
                    }
                }
            }*/
            }
        }

        .gallerySlider,
        .gallerySliderNav {
            padding-top: 1rem;
        }

        .gallerySliderNav {
            @media(max-width: $S_Max) {
                display: none;
            }

            .splide__slide {
                /*opacity: 0.8;*/
                border: 2px solid white;

                &.is-active {
                    border: 2px solid $secondaryColour;
                }

                &:before {
                    content: ' ';
                    background-color: $greyXXXLight;
                    position: absolute;
                    inset: 0;
                    z-index: -1;
                }

                &:after {
                    // overlay
                    content: ' ';
                    position: absolute;
                    inset: 0;
                    z-index: 10;
                    background-color: rgba(white, 0.1);
                    pointer-events: none;
                    transition: $transitionQuint;
                }
                /*            .bgImgWrapper {
                position: relative;
                @include aspectRatioBox(4 3);
                opacity: 0.9;

                div.bgImg {
                }
            }

            img {
                opacity: 0.8;
            }*/
                @include focusAndHover {
                    &:after {
                        background-color: rgba(white, 0);
                    }
                    /*opacity: 1;*/
                    /*                img,
                .bgImgWrapper {
                    opacity: 1;
                }*/
                }
            }
        }
    }

    .fancybox-caption {
        display: none !important; // ToDo: just fix the captions instead of hiding this...
    }
    /* #endregion */
    /****************************************************************************************************/
    /****************************************************************************************************/
    /* #region Page - Home Page */
    /****************************************************************************************************/
    .homePage {
        .careersWrapper {
            text-align: center;

            h2:after {
                content: none;
            }

            p {
                max-width: 65vw;
                margin: 0 auto 2rem;
            }
        }

        .servicesWrapper {
            &.opacityWrapper {
                padding: 0;
            }

            .cardView.expertise {
                border: none;
                justify-content: flex-start;

                h3 {
                    text-align: left;
                }

                &:hover {
                    background-color: transparent;
                }
            }

            .fullPhotoWrapper {
                position: relative;

                h2 {
                    position: relative !important;
                    left: inherit;
                    transform: none;
                    margin-top: 0;
                    padding-top: 0;
                }

                #fullPhotoSlider {
                    position: absolute;
                    height: 100%;

                    .imgWrapper {
                        &:after {
                            content: none;
                        }

                        img {
                            transition: $transition;
                            transition-delay: 0;
                            transition-duration: 3s;
                            transform: scale(1);
                        }
                    }

                    .is-active {
                        .imgWrapper {
                            img {
                                transform: scale(1.1);
                            }
                        }
                    }
                }


                #mainSlider {
                    padding: 8rem 0 3rem;

                    li + li {
                    }
                }
            }

            .splide {
                .itemContent {
                    background: rgba(1, 60, 172,.7);
                    padding: 2rem;
                    opacity: 0;
                    transition: $transition;

                    .hiddenContent {
                        padding-bottom: 3rem;
                    }

                    h3 {
                        margin-bottom: 1rem;
                        padding-bottom: .5rem;
                        position: relative;
                        transition: $transition;

                        &:after {
                            content: '';
                            position: absolute;
                            height: 4px;
                            width: 2rem;
                            background-color: $tertiaryColour;
                            left: 0;
                            bottom: 0;
                            transition: .2s ease-in .4s;
                        }
                    }

                    h3,
                    .summary,
                    .button {
                        transition: all 0.1s ease;
                    }
                }


                .splide__track--nav > .splide__list > .splide__slide.is-active {
                    border-color: transparent;

                    .itemContent {
                        opacity: 1;
                    }

                    h3,
                    .summary {
                        // color: $tertiaryColour;
                    }

                    .button {
                        position: relative;
                        z-index: 1;
                        border-color: #fff;
                        background-color: $secondaryColour;

                        &:hover {
                            background-color: $tertiaryColour;
                        }
                    }
                }

                .sliderFooter .splide__arrows {
                    .splide__arrow {
                        border: solid 1px #fff;
                        color: #fff;

                        &.splide__arrow--prev {
                            transform: rotate(90deg);
                            top: 0;
                            background: rgba(1, 60, 172,.7);
                        }

                        &.splide__arrow--next {
                            bottom: 0;
                            left: 0;
                            background: rgba(1, 60, 172,.7);
                        }
                    }
                }
            }
        }

        .insightsWrapper {
            .splide {
                /*.splide__track {
                background: linear-gradient(90deg, rgba(0,42,206,0) 48%, rgba($tertiaryColour,1) 48%, rgba($tertiaryColour,1) 48.25%, rgba(0,90,206,0) 48.25%);
            }*/
                .slide {
                    width: 25% !important;
                    padding-right: 2rem;

                    .innerContent {
                        font-size: 85%;

                        h3 {
                            font-size: 1.5rem;
                        }

                        .summary {
                            transition: $transition;
                        }
                    }

                    &:after {
                        content: '';
                        height: 0;
                        width: 5px;
                        position: absolute;
                        right: 2rem;
                        top: 50%;
                        background-color: $tertiaryColour;
                        transition: all 1s ease;
                    }

                    &.is-active {
                        width: 50% !important;
                        padding-left: 0;

                        .innerContent {
                            font-size: inherit;

                            h3 {
                                font-size: 2.2rem;
                            }

                            .summary {
                                height: 0;
                                opacity: 0;
                            }
                        }

                        &:after {
                            height: 100%;
                            top: 0;
                        }
                    }
                }
            }
        }

        .newsWrapper {
            .cardView.insight .itemImage .imgWrapper img {
                aspect-ratio: 3;
            }
        }
    }
    /* Lawyer Search (FindASearch) */
    .findASearch {
        margin: 0 auto 3em;
        padding: 0 0 3rem;
        text-align: center;
        ////
        height: 0;
        padding: 0;
        margin: 0;
        position: relative;
        //
        .offsetWrapper {
            height: 9rem; // matches the padding-bottom of the homeSlideshow slide
            display: flex;
            /*        flex-direction: column;
        justify-content: center;*/
            //margin-top: -11rem;
            position: absolute;
            top: auto;
            left: 0;
            right: 0;
            bottom: 0;
        }

        .k-autocomplete {
            background: none;
            border: none;
            margin: 0 auto;
            width: 40rem;
            max-width: 100%;

            input[type=text] {
                color: #fff;
                width: 100%;
                height: 3.15rem;
                max-width: 100%;
                padding: 0.2rem 2rem;
                text-overflow: ellipsis;
                text-transform: uppercase;
                background-color: transparent;
                font-size: 1.2rem;
                font-weight: $fontWeightLight;
                transition: $transition;
                background-image: url(/CFImages/Icons/search-blue.svg);
                background-repeat: no-repeat;
                background-position: center right 1rem;
                outline-offset: -2px;
                border: solid 1px $greyXXLight;
                box-sizing: border-box;
                height: 4rem;
                //text-align: center;

                &:focus {
                    outline: none !important;

                    &::placeholder {
                        display: none;
                    }
                }

                &::placeholder {
                    color: #fff;
                    text-transform: uppercase;
                    font-weight: 300;
                }
            }
        }

        .k-widget {
            /* the animated underline */
            background: none;

            &:focus,
            &:active,
            &:hover,
            &.k-state-focused {
                outline: none !important;
            }
        }
        ////
        .k-widget.k-autocomplete {
            //width: 100%;
            color: $grey;
            border: none;
            box-shadow: none;
        }
        // the dropdown
        #findALawyerDropdownContainer {
            .k-list {
                .k-item {
                    &:first-of-type {
                        display: none !important;
                    }
                }
            }

            .k-list-container .k-popup {
            }
        }
    }

    .k-list-container.k-popup {
        background-color: $primaryColour;

        .k-item {
            text-transform: uppercase;

            &:hover {
                background-color: $tertiaryColour;
                color: #fff;
            }
        }
    }

    .findAnything {
        fieldset {
            border: none;
            padding: 0;
        }
    }
    /* #endregion */
    /****************************************************************************************************/
    /****************************************************************************************************/
    /* #region Page - Careers (and child pages) */
    /****************************************************************************************************/
    .bgImgGradientGrid {
        @include grid(2, 0, ".col");

        .content {
            padding: 6rem 0;
            position: relative;
            z-index: 10;
            width: 30%;
            width: 36rem;
            flex: 0 1 auto;
        }

        .image {
            position: relative;
            z-index: 1;
            flex: 1 1 auto;

            &:after {
                content: ' ';
                position: absolute;
                inset: 0; // to deal with a bug
                /*background-image: linear-gradient(to right, scrim-gradient($greyXXLight));*/
                background-image: linear-gradient(to right, rgba($greyXXLight, 1), rgba($greyXXLight, 1) 50%, rgba($greyXXLight, 0));
                z-index: 1;
                pointer-events: none;
                width: 100vw;
                left: -50vw;
            }
        }
    }
    /* #endregion */
    /****************************************************************************************************/
    /****************************************************************************************************/
    /* #region PDF Generation (Printable Bios) */
    /****************************************************************************************************/
    #printModal_background {
        background: none !important;
    }

    #printModal {
        display: none;
    }

    .printModalWrapper #printModal {
        display: block;
    }

    #printModal {
        width: 36rem;
        background-color: white;
        padding: 4rem;
        padding-top: 3rem;
        border: $border;

        .printModal {
        }

        .top {
            h2 {
                margin-bottom: 0;
            }

            p {
                color: $primaryColour;
            }
        }

        .bottom {
            margin: 1.4rem 0 1.1rem;

            .options {
                ul {
                    li {
                        display: flex;
                        align-items: center;
                        margin-bottom: 0.4em;
                    }
                }
            }
        }

        .label {
            @include smallcaps;
            letter-spacing: $letterSpacingMid;
            font-size: 0.7rem;
            color: $primaryColour;
            margin-top: 0;
        }

        h3 {
            color: $secondaryColour;
            margin-top: 0;
            margin-bottom: 0.6rem;
        }

        .button.close {
            inset: initial;
            top: 2.6rem;
            right: 2.6rem;
            margin: 0;
        }

        button[type=submit] {
            margin-left: auto;
            padding: 0.8em 2em 0.8em 1.6em;

            .icon,
            svg {
                margin-right: 1rem;
                font-size: 1.5rem;
            }

            .fa-file-download {
                display: none;
            }

            &:focus,
            &:hover {
                .fa-file-pdf {
                    display: none;
                }

                .fa-file-download {
                    display: inline-block;
                }
            }
        }
    }

    @keyframes fadeToGreyscaleWhite {
        0% {
            filter: grayscale(0) brightness(1);
        }

        1% {
            filter: grayscale(1) brightness(1);
        }

        100% {
            filter: grayscale(1) brightness(200);
        }
    }

    html.popup_visible_printModal {
        #wrapper > main {
            filter: blur(5px);
        }
    }
    /* #endregion */
    /****************************************************************************************************/
    /*Filtered content*/
    .pagination-page {
        color: $primaryColour;

        .selected {
            color: #ffffff;
            background-color: #940e14;
            padding: 4px;
            text-align: center;
            width: 30px;
            height: 30px;
            border-radius: 90px;
            display: inline-block;
            line-height: 100%;
        }
    }

    #ClearFiltersWrapper {
        position: fixed;
        z-index: 10000;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        width: 45px;
        height: 45px;
        right: 5%;
        bottom: 10%;
        background: $primaryColour;
        border: solid #fff 1px;
        border-radius: 40px;
        box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.2);
        color: #fff;
        opacity: 0.45;
        transition: all linear 0.15s;
        cursor: pointer;

        span.horizontal-slash {
            position: absolute;
            transform: rotate(35deg);
            height: 3px;
            width: 100%;
            background: #fff;
        }
    }

    #ClearFiltersWrapper:hover {
        opacity: 1;
    }

    .goToPageLbl {
        display: block;
        max-width: 100px;
        padding: 3px;
        border-radius: 5px;
        text-align: center;
        margin: 15px auto;
        position: relative;
        transition: all linear 0.15s;

        select {
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            z-index: 999;
            margin: 0 auto;
            height: auto;
        }

        .goToDropdown {
            height: 0px;
            max-width: 80px;
            margin: 0 auto;
            overflow-x: hidden;
            overflow-y: hidden;
        }

        .goToDropdown.active {
            height: 140px;
            border: solid $primaryColour 1px;
            border-radius: 6px;
            overflow-y: auto;
        }

        li {
            margin-right: 3px;
        }

        li.selected {
            background: $greyXXXLight;
        }
    }

    .goToPageLbl:hover {
        color: $primaryColour;
    }
    /* #endregion */
    /****************************************************************************************************/
    /****************************************************************************************************/
    /* #region Bookmarks */
    /****************************************************************************************************/

    #BookmarkLoading {
        display: none;
        z-index: 9999;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }


    .bookmark-modal,
    #UserBookmarkModal {
        display: none;
        background: rgba(0, 0, 0, 0.1);
        position: fixed;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        z-index: 999;
        width: 100%;
        height: 100%;


        .global-bookmark-list {
            .global-bookmark-group {
                margin: 0.5rem 0 0 0;
                padding: 0.25rem 0;

                .group-opts {
                    text-align: right;

                    a {
                        margin-right: 15px;
                        cursor: pointer;
                    }
                }
            }
        }

        #UserBookmarkInnerWrapper {
            height: auto;
            min-height: 450px;
        }

        .bookmark-wrapper,
        #UserBookmarkInnerWrapper {
            padding: 15px 35px;
            background: #ffffff;
            position: relative;
            max-width: 1300px;
            overflow-y: auto;
            overflow-x: hidden;
            width: 100%;

            #BookmarkGroupNew {
                display: none;
            }

            #BookmarkGroupOpen,
            #BookmarkGroupClose {
                float: right;
            }

            .bookmark-options {
                margin: 1rem 0 0 0;
                text-align: center;
            }

            .bookmark-options.text-right {
                text-align: right !important;
            }

            .bookmark-title {
                a {
                    display: inline;
                }

                a.rm-bookmark {
                    float: right;
                }
            }

            .bookmark-options button.btn {
                width: 49%;
            }

            .bm-public,
            .bm-editable {
            }
        }

        #BookmarkGroupDeleteConfirmDialog,
        #BookmarkGroupDocumentOptions {
            position: absolute;
            display: none;
            align-items: center;
            justify-content: center;
            text-align: center;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 999;
            background: #fff;
            padding: 20px;

            h3 {
                margin-top: 0px;
            }

            p {
                padding: 20px;
            }

            .doc-opt-group {
                margin-bottom: 20px;

                .doc-type-options {
                    input {
                        margin-right: 20px;
                    }
                }
            }

            .doc-related-options {
                margin: 0 20px 15px;
                text-align: left;
                display: inline-block;
            }

            label:before {
                display: none !important;
            }

            label:after {
                display: none !important;
            }

            .doc-param,
            .doc-param-all {
                width: 20px;
                height: 20px;
                margin-right: 10px;
                position: relative !important;
            }
        }

        .global-bookmark-list {
            overflow-y: auto;
            overflow-x: hidden;
            max-height: 400px;
            width: 100%;
            margin: 1rem 0;
            padding: 1rem 2rem;
            border-bottom: $border;

            h3.ownedByTitle {
                display: block;
                margin: 2rem 0 0.5rem 0;
                padding: 0;
                font-size: 0.8rem;
                font-style: italic;
                color: $bodyFontColour;
                line-height: 1rem;
            }
        }

        .bookmark-wrapper {
            max-width: 350px;
        }

        #CloseGlobalBookmarkModal {
            float: right;
        }

        .global-bookmark-group {
            display: none;
            padding-bottom: 0.5rem;
            margin-bottom: 0.5rem;
            border-bottom: $border;

            .permissionActionsGrid {
                display: grid;
                grid-template-columns: 40% 30% 30%;

                &.layout {
                    padding: 0;
                }

                .title {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;

                    h3 {
                        margin: 0;
                    }
                }

                .actions {
                    /* print and deleted */
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 1rem;
                    font-size: 1rem;
                    cursor: pointer;
                    color: $bodyFontColour;
                }

                .permissions {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    align-items: center;
                    gap: 1rem;
                    position: relative;
                    /* public and editable */
                    span {
                        /* Checkboxes */
                        input[type='checkbox'] {
                            // Default (unchecked) state
                            width: 16px;
                            height: 16px;
                            cursor: pointer;
                            position: absolute;
                            top: auto;
                            appearance: inherit;
                            border-radius: 3px;

                            + label {
                                padding-left: 2rem;
                            }

                            &:focus {
                                outline: none !important;
                            }

                            &:focus:focus-visible {
                            }

                            &:checked {
                                // checked icon
                                background-color: $secondaryColour;
                                border-color: $secondaryColour;
                                background-image: url(/CFImages/Icons/check-white.svg);
                            }
                            /* disabled */
                            $disabledColour: $greyLight;

                            &:disabled {
                                color: $disabledColour;
                            }
                        }
                    }
                }
            }
        }

        .global-bookmark-group.show {
            display: block; // !important;
        }
    }

    #BookmarkMessageWrapper {
        display: none;
        position: fixed;
        z-index: 9999;
        border-radius: 8px 0px 0px 8px;
        right: 0;
        bottom: 0;
        max-width: 350px;
        width: 100%;
        -webkit-box-shadow: -5px 0px 15px 0px rgba(0, 0, 0, 0.3);
        box-shadow: -5px 0px 15px 0px rgba(0, 0, 0, 0.3);
        background: $offWhite;

        #BookmarkMessage {
            padding: 20px;
            margin: 0px;
            font-size: 20px;

            .text-success {
                color: $primaryColour;
            }

            .text-danger {
                color: #ce1f1f;
            }

            .text-warning {
                color: #e08f10
            }
        }
    }

    #DropZone {
        .global-bookmark-group {
            box-shadow: $boxShadow;
            border: 1px solid $greyLight;
            margin: 1rem 0 2rem 0;
            padding-bottom: 2rem;

            .permissionActionsGrid {
                display: grid;
                grid-template-columns: 40% 30% 30%;

                &.layout {
                    padding: 0rem 1rem;
                    margin: 0;
                }

                .title {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    .bmg-sort {
                        margin: 0;
                        padding: 0;
                    }

                    .group-name-text {
                        &[contenteditable="true"] {
                            border: 1px solid $grey;
                            padding: 0.5rem 3rem 0.5rem 0.5rem;
                            position: relative;
                            cursor: pointer;

                            &:after {
                                content: ' ';
                                background-image: url(/CFImages/Icons/pen.svg);
                                background-repeat: no-repeat;
                                position: absolute;
                                right: 0.5rem;
                                top: 0.5rem;
                                width: 1rem;
                                height: 100%;
                            }
                        }
                    }
                }

                .actions {
                    /* print and deleted */
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 1rem;
                    font-size: 1.4rem;
                    cursor: pointer;
                    color: $bodyFontColour;
                }

                .permissions {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    align-items: center;
                    gap: 1rem;
                    position: relative;
                    /* public and editable */
                    span {
                        /* Checkboxes */
                        input[type='checkbox'] {
                            // Default (unchecked) state
                            width: 20px;
                            height: 20px;
                            cursor: pointer;
                            position: absolute;
                            top: auto;
                            appearance: inherit;
                            border-radius: 3px;

                            + label {
                                padding-left: 2rem;
                            }

                            &:focus {
                                outline: none !important;
                            }

                            &:focus:focus-visible {
                            }

                            &:checked {
                                // checked icon
                                background-color: $secondaryColour;
                                border-color: $secondaryColour;
                                background-image: url(/CFImages/Icons/check-white.svg);
                            }
                            /* disabled */
                            $disabledColour: $greyLight;

                            &:disabled {
                                color: $disabledColour;
                            }
                        }
                    }
                }
            }

            .bmc-sort {
                position: relative;
            }
            /* .bmc-sort::before {
            content: "Group Drag ::before attribute";
            display: inline;
            position: absolute;
            top: -20px;
            left: 0;
        }*/

            .bmg-sort {
                margin: 10px 10px 20px;
                padding-bottom: 5px;
                display: inline-block;
            }

            .bmg-sort {
                //border-bottom: solid $primaryColourLight 1px;

                .fa-grip-vertical {
                    font-size: 26px;
                }
            }

            .group-opts {
                float: right;
            }
        }

        .bookmark-items {
            padding: 1rem 1rem;

            .bmc-sort {
                margin: 10px;
            }

            .bookmark-item {
                min-width: 100px;
                margin-bottom: 2rem;

                .bookmark-opts {
                    .rm-bookmark {
                        float: right;
                    }
                }
            }
        }
        // Sorting
        .bmg-sort.global-bookmark-group {
        }
    }
    /* to prevent the flicker when bookmarks loaded/unloaded on page */
    #BookmarkPageListContainer.hide {
        display: none !important;
    }
    /* #endregion */
    /****************************************************************************************************/
    /****************************************************************************************************/
    /* #region Insights (shared styles) - Cards and Detail View  */
    /****************************************************************************************************/
.wrapper.careersWrapper, .wrapper.accordionWrapper {
    .k-treeview {
        .listView {
            --card-width: 100%;
            --card-gap: 1px;
            margin-bottom: 0;

            .k-item {

                .k-icon:before {
                    background-image: url(/CFImages/Icons/plus-white.svg);
                }

                .k-icon.k-i-collapse:before {
                    background-image: url(/CFImages/Icons/minus-white.svg);
                }

                .k-in {
                    width: 100%;
                    background-color: $secondaryColour;
                    padding: 1rem 2rem;
                    white-space: normal;

                    h4 {
                        margin: 0;
                        padding: 0;
                        color: #fff;
                    }
                }

                .k-content {
                    display: block;
                    background-color: $greyXXLight;
                    padding: 2rem 2rem 0 2rem;
                    white-space: normal;

                    .k-in {
                        display: none;
                    }

                    .listView.teammembers {
                        li {
                            margin-bottom: 2rem;

                            .teammemberGrid {
                                display: grid;
                                grid-template-columns: 1fr 3fr;

                                @media only screen and (max-width: $S_Mid) {
                                    grid-template-columns: 1fr;
                                }
                                 
                                gap: 2rem;

                                .teamLeft {
                                    li {
                                        margin-bottom: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
    /* #endregion */
    /****************************************************************************************************/
    /* #region Timeline  */
    /****************************************************************************************************/



    .timelinepoints {
        position: relative;

        .timelineScroll {
            position: absolute;
            top: 0;
            width: 2px;
            height: 100%;
            background-color: $tertiaryColour;
            left: 50%;
            transform: translatex(-50%);
        }

        .timelineTop {
            position: absolute;
            top: 0;
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
            background-color: $tertiaryColour;
            left: 50%;
            transform: translatex(-50%);
        }

        .timelineBar {
            position: sticky;
            width: 8px;
            height: 10vh;
            background-color: $tertiaryColour;
            margin: 0;
            top: 50vh;
            left: 50%;
            transform: translatex(-50%);
        }

        .opacityWrapper {
            padding: 0 0 2rem;

            h3 {
                text-align: center;
                background-color: $greyXXLight;
                padding: .5rem;
                font-weight: bold;
                font-size: 2.2rem;
            }

            .itemContent {
                display: grid;
                gap: 5rem;
                justify-content: space-between;
                grid-template-columns: 1fr 1fr;

                .leftContent,
                .rightContent {
                    position: relative;
                    padding-top: 1rem;
                    color: $primaryColour;

                    &:before {
                        content: '';
                        width: 3rem;
                        height: 6px;
                        background-color: $tertiaryColour;
                        top: 0;
                        left: 0;
                        position: absolute;
                    }
                }
            }
        }
    }
    /* #endregion */
    @keyframes move-bg {
        to {
            background-position: var(--bg-size) 0;
        }
    }

    @keyframes slide-in-left {
        0% {
            -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
            opacity: 0;
        }

        100% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 1;
        }
    }


    @keyframes bg {
        0% {
            background-size: 0 3px, 3px 0, 0 3px, 3px 0;
        }

        25% {
            background-size: 100% 3px, 3px 0, 0 3px, 3px 0;
        }

        50% {
            background-size: 100% 3px, 3px 100%, 0 3px, 3px 0;
        }

        75% {
            background-size: 100% 3px, 3px 100%, 100% 3px, 3px 0;
        }

        100% {
            background-size: 100% 3px, 3px 100%, 100% 3px, 3px 100%;
        }
    }
    /* #endregion */
    /****************************************************************************************************/
    /****************************************************************************************************/
    /* #region Home Page Line Animation  */
    /****************************************************************************************************/

    .lines_bg {
        display: none;
    }

    .homePage {
        .lines_bg {
            /*position: fixed;*/
            position: absolute;
            inset: 0;
            opacity: 0;
            display: block;

            &.animate {
                opacity: 1;
            }

            svg {

                path,
                line {
                    transition-delay: 0ms;
                }
            }

            &.animate {
                svg {

                    path,
                    line {
                        transition: all 1.5s ease !important;
                        stroke-dashoffset: 0px !important;
                    }
                }
            }
        }
    }


    @keyframes textclip {
        to {
            //background-position: 200% center;
            background-position: 0% 0%;
        }
    }
    /* #endregion */
    /****************************************************************************************************/


    h2 .highlight,
    .topperSubtitle span {
        display: inline-block;
        // background-image: linear-gradient(-225deg, #ffffff 0%, #ffffff 33%, $tertiaryColour 66%, $tertiaryColour 100%);
        background-image: linear-gradient(-225deg, $tertiaryColour 0%, $tertiaryColour 33%, #ffffff 66%, #ffffff 100%);
        background-size: auto auto;
        background-clip: border-box;
        background-size: 300% auto;
        // background-position: 0% 0%;
        background-position: 100% 0%;
        color: #fff;
        background-clip: text;
        text-fill-color: transparent;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        animation-fill-mode: forwards;
        ///
        //animation: textclip 2s linear; // original duration (2s)
        animation: textclip 2.5s linear;
        animation-fill-mode: forwards;
        line-height: 1.3;
    }



