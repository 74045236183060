﻿/// <reference path="_variables.scss" />
/// <reference path="_mixins.scss" />
/// <reference path="_placeholders.scss" />
/// <reference path="_grids.scss" />

/****************************************************************************************************/
/* #region Kendo Navigation top-level widget overrides */
/****************************************************************************************************/

/****************************************************************************************************/
/* Kendo Menu Widget */
.k-menu {
    &.k-menu-horizontal {
        border: none;
        background: none;
    }

    .k-item {
        .k-link {
            &:focus {
                outline: none;
            }

            &:focus-visible {
                outline: 2px solid red;
            }
        }
    }

    &:not(.k-context-menu) {
        > .k-item {
            &.k-state-focused {
                outline: none;
                box-shadow: none;
            }

            > a.k-state-active {
                color: white;
            }

            .k-group {
                visibility: visible !important;
            }
        }

        &:focus {

            > .k-item {

                &.k-state-focused {
                    outline: none;
                    box-shadow: none;
                }
            }

            &:focus-visible {
                outline: 1px dashed $greyLight;
                outline-offset: 0;

                > .k-item.k-state-focused {
                    outline: $outline;
                    box-shadow: none;
                }
            }
        }
    }
}

/****************************************************************************************************/
/* Kendo Treeview (shared, general treeview styles) */
/* remember - these styles will affect ANY kendo treeview. So if you're making heavy changes then you probably want to target your specific treeview */
.k-treeview {
    padding: 0;
    /*    overflow-y: auto;
    overflow-x: hidden;*/

    .k-item {
        color: inherit;
        position: relative;
        padding: 0;
        font-size: 1rem;
        border: none;

        &:hover {
        }

        &.k-first {
        }

        &.k-last {
        }

        .k-icon {
            right: 5%;
            position: absolute;
            z-index: 3;
        }

        .k-icon:before {
            color: #fff;
            content: " ";
            font-size: 1rem;
        }

        .k-group {

            .k-item {
                .k-link {
                }
            }
        }
    }

    .k-link {
        color: inherit;
        width: 100%;
        font-size: inherit;
        padding: 0;

        &.k-state-hover,
        &.k-state-active,
        &.k-state-selected,
        &.k-state-focused {
            background: none;
            box-shadow: none;
            text-decoration: none;
        }

        &.k-state-hover {
        }
    }

    &:focus {
        &:focus-visible {
            .k-state-focused {
                outline: $outline;
                outline-offset: 0;
            }
        }
    }
}


/* #endregion */
/****************************************************************************************************/

/* CF Default Menu */
nav.mainNavWrapper {

    .rootMenuItem > ul {
        display: none;
    }


    ul.mainNav {
        display: none;
    }

    ul.mainNav.initialized {
        display: flex;
    }

    ul.mainNav.k-menu, ul.mainNav.k-menu:not(.k-context-menu) {
        display: flex;
        z-index: 2;
        margin-top: .5rem;
        justify-content: center;

        &:not(.k-widget) {
            ul {
                position: absolute;
                visibility: hidden;
                pointer-events: none;
            }
        }

        li.rootMenuItem {
            border: none;
            background: none;
            text-align: left;
            text-transform: uppercase;

            &.k-last {
                > a {
                    padding-right: 0;
                }
            }

            > a {
                color: #fff;
                padding: 6px 48px 6px 0;
                font-weight: 700;
                border: none;
                background: none;
                font-size: 1rem;

                &:focus, &:active {
                    /*outline: 2px solid $focusColour;
                    outline-offset: 2px;*/
                }

                &:after {
                    content: "";
                    width: 0;
                    height: 2px;
                    transition: $transition;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    background-color: $tertiaryColour;
                }
                /* The down icons */
                .k-icon {
                    margin: -2px -5px 0 5px;
                    color: $secondaryColour;

                    .k-icon:before {
                        color: #fff;
                    }
                }

                &.k-state-active {
                    background: none;
                    color: #fff;

                    &:after {
                        width: 30%;
                        transition: $transition;
                    }
                }

                &.selected, &.childSelected {

                    &:after {
                        width: 30%;
                        transition: $transition;
                        background-color: $tertiaryColour;
                    }
                }
            }
            /* hover state */
            &.k-state-hover {
                > a {
                    &:after {
                        width: 30%;
                        transition: $transition;
                    }
                }
            }
        }
        /*
        .k-animation-container:before {
            content: '';
            display: block;
            width: 0;
            height: 0;
            position: absolute;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 8px solid #F6F5FA;
            left: 2rem;
            top: -8px;
        }*/
        /* Sub elements */
        ul {
            border: none;
            padding: 0;
            position: absolute;
            background-color: #F6F5FA;

            li + li {
                border-top: solid 1px $greyXLight;
            }

            > li {
                border: none;
                background: none;


                > a {
                    min-width: 180px;
                    color: $primaryColour;
                    border: none;
                    padding: .75rem 2rem .75rem 1rem;
                    font-size: .8rem;
                    background: none;
                    font-weight: 700;
                    //text-transform: uppercase;
                    /* The down icons */
                    .k-icon {
                        //margin: -7px -5px 0 5px;
                        margin: 0;
                    }
                }
                /* hover state */
                &.k-state-hover {
                    color: #fff;
                    background-color: $tertiaryColour;

                    a.k-link {
                        //color: $primaryColour;
                    }
                }
            }
        }
        /* selected states (CFAdded selected or childSelected classes) */
        // selected item
        .rootMenuItem.k-item.selected {
            a.k-link.selected {
            }

            &:after {
                opacity: 1;
            }
        }
        // Has a child item selected
        .rootMenuItem.childSelected {
            a.k-link.childSelected {
            }

            &:after {
                opacity: 1;
            }
        }
        // selected Item
        li.selected {

            a.selected {
            }
        }
        // Has a child item selected
        /*.k-group .k-item.childSelected {
            
        }*/
        /* (The Mega Dropdown) */
        .expertises .k-animation-container {
            width: 800px !important;
            max-height: 600px !important;
            display: none;
            left: -130px !important;

            .k-item {
                display: inline;
                background-color: $secondaryColour;
                color: #fff;
                padding: 1rem;

                &:hover {
                    color: $primaryColour;
                    background-color: $tertiaryColour;
                }

                .k-link {
                    padding: 0;
                    color: #fff;
                    margin: 0;
                    justify-content: center;
                }
            }

            .expertises.k-group {
                display: flex !important;
                width: 100%;
                padding: 1.5rem;

                .k-content.megaContainer {
                    background-color: transparent;
                    border: none;
                    border-bottom: solid 1px $greyXLight;
                    margin-bottom: 1rem;

                    h4 {
                        font-size: 1.4rem;
                        margin: 0;
                        padding: 0 0 1.25rem 1rem;
                        text-transform: capitalize;

                        a {
                            color: $primaryColour;
                        }
                    }

                    ul.megaExpertise {
                        position: relative;
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        //GRID-TEMPLATE-ROW IS DEFINED IN THE TEMPLATE AND IS BASED ON COUNT
                        grid-auto-flow: column;

                        li:first-of-type {
                            border-top: solid 1px $greyXLight;
                        }
                    }
                }
            }
        }
    }
}
/* #endregion */
/****************************************************************************************************/

/****************************************************************************************************/
/* #region Hamburger */
/****************************************************************************************************/

button.hamburger {
    top: 0.5rem;
    right: 3rem;
    width: $hamburgerWidth;
    height: $hamburgerHeight;
    border: none;
    padding: 12px;
    margin: 0;
    position: absolute;
    z-index: 500;
    transition: $transition;
    //background-color: rgba(white, 0.8);
    display: none;

    &:hover {
        cursor: pointer;
        background-color: $tertiaryColour;
    }

    .hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
        position: absolute;
        width: 34px;
        height: 2px;
        transition-timing-function: ease;
        transition-duration: .15s;
        transition-property: transform;
        background-color: #fff;
    }

    .hamburger-inner {
        top: 50%;
        left: 50%;
        display: block;
        transform: translate(-50%, -50%) rotate(0deg);
        transition-timing-function: cubic-bezier(.55,.055,.675,.19);
        transition-duration: 75ms;

        &:before {
            top: -10px;
            transition: top 75ms ease .12s,opacity 75ms ease;
        }

        &:after {
            bottom: -10px;
        }

        &:after,
        &:before {
            display: block;
            content: "";
        }
    }

    &.open {
        z-index: 10000;
        background-color: $tertiaryColour;
        $hamburgerTransitionDelay: .12s;
        $hamburgerTransitionDuration: 75ms;

        .hamburger-inner {
            transition-delay: $hamburgerTransitionDelay;
            transition-timing-function: cubic-bezier(.215,.61,.355,1);
            transform: translate(-50%, -50%) rotate(45deg);

            &:before {
                top: 0;
                transition: top $hamburgerTransitionDuration ease,opacity $hamburgerTransitionDuration ease $hamburgerTransitionDelay, color $hamburgerTransitionDuration ease-in;
                opacity: 0;
            }

            &:after {
                bottom: 0;
                transition: bottom $hamburgerTransitionDuration ease,transform $hamburgerTransitionDuration cubic-bezier(.215,.61,.355,1) $hamburgerTransitionDelay;
                transform: rotate(-90deg);
            }
        }
    }
}
/* #endregion */
/****************************************************************************************************/

/****************************************************************************************************/
/* #region Mobile Menu */
/****************************************************************************************************/

/* sometimes a duplicate of the header, sometimes just a container for the mobile nav */
.mobileHeader {
    position: fixed;
    z-index: 1000;

    &.withNavOpen {
    }
}
/* tweak other elements when the mobile nav is open */
body.withNavOpen {
    #header {
        top: 0;
        left: 0;
        right: 0;
        bottom: initial;
        position: fixed;
    }
}
/* the mobile nav menu */
nav.mobileNav {
    top: 0;
    right: 0;
    width: 65%;
    max-width: 100%;
    height: 100%;
    position: fixed;
    overflow-x: hidden;
    overflow-y: auto;
    min-height: 100vh;
    transform: translateX(100%);
    transition: $transition, visibility linear 0s;
    visibility: hidden;
    background: rgba($secondaryColour, .95);

    &.open {
        transform: translateX(0%);
        visibility: visible;
    }

    .cmsContent {
        padding: 0 1rem 1rem;

        .socialLinks {
            a {
                color: #fff;

                &:hover {
                    color: $primaryColour;
                }
            }
        }
    }

    .k-treeview {
        color: white;
        margin-top: 4rem;
        margin-bottom: 2rem;

        .k-group {
            .rootMenuItem.k-item {

                .k-link {
                    font-size: 1.5rem;
                    padding: .5rem 1rem;
                    border-left: none;
                    border-right: none;

                    &:hover {
                        background-color: $secondaryColourDark;
                        color: #fff;
                    }
                }

                .k-icon.k-i-expand:before {
                    background-image: url(/CFImages/Icons/caret-down-solid.svg);
                }

                .k-icon.k-i-collapse:before {
                    background-image: url(/CFImages/Icons/caret-up-solid.svg);
                }

                .k-group {
                    .k-item {
                        .k-link {
                            font-size: 1.2rem;

                            &:hover {
                                background-color: $secondaryColourDark;
                            }
                        }
                    }
                }
            }

            .k-item + .k-item {
                border-top: solid 1px $secondaryColourLight;
            }

            border-top: solid 1px $secondaryColourLight;
            border-bottom: solid 1px $secondaryColourLight;

        }
    }

    .button.outline {
        border:solid 1px #fff !important;
        color:#fff !important;
    }
}
/* #endregion */
/****************************************************************************************************/

/****************************************************************************************************/
/* #region Top Level Simple */
/****************************************************************************************************/


nav.topLevelSimple {
    width: 100%;

    ul {
        display: flex;
        justify-content: space-between;

        a {
            text-decoration: none;
            text-transform: uppercase;
            color: #fff !important;
            opacity: .9;

            &:hover {
                color: $tertiaryColour !important;
            }
        }
    }
}
/* #endregion */
/****************************************************************************************************/

/****************************************************************************************************/
/* #region Kendo TreeView inside sideContent */
/****************************************************************************************************/

.sideContent {
    nav {
        width: 100%;
        max-width: 300px;
    }
}

.k-treeview {
    ul.mainTreeView {
        border-top: 1px solid $secondaryColour;
        border-bottom: 1px solid $secondaryColour;
        padding: 0;

        li.k-item {
            /* above the link */
            .k-top, .k-mid, .k-bot {
                padding: 2px;
            }
            /* link styles */
            a.k-link {
                color: $greyDark;
                font-size: 1rem;
                padding: 0.5rem 2rem 0.5rem 2rem;
                border: 2px solid transparent;

                @include focusAndHover {
                    border: 2px rgba($secondaryColour,.5) solid;
                }
            }
            /* These are any nested groups */
            ul.k-group {
                background-color: $offWhite;

                li.k-item {
                    a.k-link {
                        padding-left: 3rem;
                        font-size: 0.9rem;

                        &:after {
                            width: 8px;
                            height: 8px;
                        }
                    }
                    /* Level 2 Nest */
                    ul.k-group li.k-item a.k-link {
                        padding-left: 4rem;
                    }
                }
            }
            /* The selected li */
            &.selected {
                background-color: $tertiaryColour;
                /* link styles (both the li and a have class of selected on them) */
                a.k-link.selected {
                    color: white;
                }
            }
            /* This is the expanded li item */
            &[aria-expanded="true"] {
                background-color: $greyLight;
            }
            /* expand collapse icons */
            span.k-icon {
                right: initial;
                left: 1.5rem;

                &.k-i-expand {
                }

                &.k-i-collapse {
                }
            }

            @include focusAndHover {
                background-color: $greyLight;
            }
        }
    }
}

/* #endregion */

/****************************************************************************************************/
/* #region Jump Menu */

/* #endregion */
/****************************************************************************************************/


/****************************************************************************************************/
/* #region Mega Menu - Disabled by default */
/****************************************************************************************************/
//Uncomment these lines in order to show dropdown container for styling
 /*.k-animation-container {
        display: block !important;
        overflow: visible !important;

        .k-menu-group {
            display: block !important;
            transform: translateY(0) !important;
        }
    }*/

/*nav ul.megaNav {*/
/* (The Mega Dropdown) */
/*.megaContainer {
        display: none; //hide until initialized
    }

    .k-animation-container {
        width: calc(60vw) !important;
        max-height: 750px !important;
        display: none;*/
/*top: 65px !important;*/
/*top: calc(100% + 3px) !important;
        border: none !important;*/
/*transform: translatex(-50%) !important;
        left: 50% !important;

        .k-item.k-state-default {
            white-space: normal !important;
        }

        .k-group.megaContainer { //individual megamenu container
            width: 100% !important;*/
/* border: solid 2px red;*/
/*box-shadow: 0px 5px 27px 7px rgba(0,0,0,0.12);
            display: flex !important;
            flex-direction: row;
            flex-wrap: wrap;
            padding: 2.5rem;

            a {
                padding-bottom: .3rem;
                padding-left: 0;

                &:after {
                    transition: $transitionMenuItem;
                }

                &:hover {
                    @extend %borderBottomShort;
                }
            }

            > li.k-item {
                display: inline-block;
                width: 100%;
                font-size: .9rem;
                padding-bottom: .5rem;

                &:hover {
                    background-color: transparent;
                }

                h4 {
                    margin-top: 0;
                }

                .k-content {
                    background-color: transparent;

                    .level1 {
                        li {
                            padding-bottom: .5rem;
                        }
                    }

                    .button {
                        white-space: normal;
                        text-align: left;

                        a:hover {
                            color: #fff;
                        }
                    }
                }
            }

            &.commonPanel {
                column-count: 2;
                display: block !important;
            }

            &.expertises {
                display: flex;

                ul {
                    margin: 0;
                }

                .k-first {
                    flex-basis: 70%;
                }

                .k-last {
                    flex-basis: 30%;
                }

                .k-content {
                    &.megaLeft {
                        margin-right: 2rem;

                        .level1 {
                            column-count: 2;
                            column-gap: 2rem;

                            li {
                                display: inline-block;
                                width: 100%;
                            }
                        }
                    }
                }
            }

            &.teammembers {
                flex-direction: column;
            }

            &.insights {
                padding: 0;

                ul.level1 {
                    column-count: 2;
                }

                .k-content {
                    flex-direction: column;
                }

                > li {
                    padding: 2.5rem;
                }

                li:first-of-type {
                    background-color: $greyXXXLight !important;
                }

                li.k-item + li.k-item {
                    padding-top: 1rem;
                }
            }
        }
    }
}*/
/* #endregion */
/****************************************************************************************************/


/****************************************************************************************************/
/* #region Nav - Description List */
/****************************************************************************************************/

/*nav.descriptionList {
    margin: 5rem 0 4.5rem !important;

    h2 {
        color: $primaryColour;
        font-size: 1rem;
        font-family: $primaryFont;
        margin-bottom: 2rem;
    }

    ul.menuItems.listView {
        @include grid(4, 2rem, "li");
        margin-top: 4rem;

        li.menuItem {
            position: relative;
            margin-bottom: 2.4rem;*/
/*flex-basis: 25%;*/
/*margin: 0;*/
/*&:before {
                content: ' ';
                position: absolute;
                inset: -1rem;
                background-color: rgba($secondaryColour, .1);
                z-index: 1;
                opacity: 0;
                display: block;
                transition: $transition;
            }

            > {
                position: relative;
                z-index: 2;
            }

            &.selected {
                //background-color: $secondaryColourTransparent;

                h3:after {
                    background-color: $secondaryColour;
                }
            }

            &:hover {
                &:before {
                    opacity: 1;
                }
            }

            .navItemInner {*/
/*padding: 1rem;*/
/*position: relative;
                z-index: 9;

                .bgImgWrapper {
                    @include aspectRatioBox(1 1, ".bgImg");
                }

                a {
                    text-decoration: none;
                    color: inherit;
                    display: block;
                }

                h3 {
                    margin: 0;
                    position: relative;
                    padding-bottom: 1.5rem;
                    color: $grey;

                    &:after {
                        content: ' ';
                        position: absolute;
                        background-color: $primaryColour;
                        height: 1px;
                        width: calc(50% - 1.5rem);
                        left: 0;
                        bottom: 0;
                    }
                }
            }
        }
    }
}*/

/* #endregion */
/****************************************************************************************************/

/****************************************************************************************************/
/* #region Nav - Thumbnail List */
/****************************************************************************************************/

/*nav.thumbnailList {

    ul.menuItems {*/
/*@include grid(4, 0.2rem, "li.menuItem");*/
/*display: flex;
        flex-wrap: nowrap;

        li.menuItem {
            flex: 1 1 0;
            margin: 0;

            &:hover {
                .navItemInner {*/
/*padding: 2px;*/
/*text-align: center;

                    .bgImgWrapper {
                        .bgImg {
                            transform: scale(1.1);
                        }
                    }
                }
            }

            .navItemInner {
                padding: 2px;
                text-align: center;

                .bgImgWrapper {
                    @include aspectRatioBox(1 1, ".bgImg");

                    .bgImg {
                        transition: $transition;
                    }
                }

                a {
                    text-decoration: none;
                    color: inherit;
                    display: block;
                }

                h3 {
                    padding-bottom: 1.5rem;
                    color: $secondaryColour;
                    margin: 1rem auto;
                }
            }
        }
    }

}*/
/* #endregion */
/****************************************************************************************************/


/****************************************************************************************************/
/* #region Sitemap */
/****************************************************************************************************/
/*nav {
    ul.siteMapComplete {
        padding-left: 2em;

        li {
            padding: 0.35em 0 0 0.25em;
            list-style-type: disc;
            text-transform: none;
        }

        li.rootMenuItem {
            list-style-type: square;
            text-transform: uppercase;

            ul {
                padding-left: 2em;
            }

            ul.level2 {
                padding-left: 4em;
            }

            ul.level3 {
                padding-left: 6em;
            }

            ul.level4 {
                padding-left: 8em;
            }
        }

        li.reset {
            padding: 0;
            margin: 0;
            list-style-type: none;
        }

        li a.toggleAction.button {
            margin: 0.5em 0 0 0;
        }
        // FAQ Toggle
        li a.toggleAction.withPlusMinus {
            .openIcon {
                display: inline-block;
            }

            .closeIcon {
                display: none;
            }
        }
        // Active FAQ Toggle
        li.active {
            a.toggleAction.withPlusMinus {
                .openIcon {
                    display: none;
                }

                .closeIcon {
                    display: inline-block;
                }
            }
        }

        div.toggleContent {
            display: none;

            &.active {
                display: block;
            }
        }
    }
}*/
/* #endregion */
/****************************************************************************************************/


/****************************************************************************************************/
/* #region Tabs / Tabstrip */
/****************************************************************************************************/
// Hide until KENDO Renders
.tabstrip {
    display: none;
}

.tabstrip.k-widget {
    display: block;

    .button .k-flat {
        display: none;
    }

    &.k-tabstrip {
        background: none;
        border: none;
        font-size: inherit;
        padding-top: 1rem;

        .k-tabstrip-items { // the ul

            border: none;
            background: none;
            text-transform: uppercase;

            .k-item { //the li (e.g. tabs)
                border: none;
                background: none;
                border-radius: 0;


                .k-loading {
                    border: none;
                }

                .k-link {
                    color: $tertiaryColour;
                    padding: 0 2rem 0;
                    font-size: 1.4rem;
                    background: none;
                    text-transform: none;
                    border-right: solid 1px $tertiaryColour;
                }

                &.k-first {
                    .k-link {
                        padding-left: 0;
                    }
                }

                &.k-last {
                    .k-link {
                        border: none;
                    }
                }

                &.k-state-active {
                    .k-link {
                        color: #fff;
                    }
                }

                &.k-state-hover {
                    .k-link {
                        opacity: .8;
                    }
                }

                &:active {
                    .k-link {
                        color: #fff;
                    }
                }
            }
        }

        &:focus {
            box-shadow: none;
        }

        @include focusVisible {
            outline: $outline;
            outline-color: $greyLight;
            outline-style: dashed;

            .k-item.k-state-active {
                outline: $outline;
            }
        }

        .k-content {
            border: none;
            padding: 0;
            background-color: transparent;
            overflow: hidden;

            &.k-state-active {
                background-color: transparent;
            }

            &:focus {
                outline: none;
            }

            @include focusVisible {
                outline: $outline;
                outline-offset: $outline_offset;
            }
        }
    }
}
/* #endregion */
/****************************************************************************************************/
