﻿/// <reference path="_variables.scss" />
/// <reference path="_mixins.scss" />
/// <reference path="_placeholders.scss" />
/// <reference path="_grids.scss" />
/* 
Media queries for tablet and mobile (general guidelines for breakpoints globally set in the _variables file) 
Mobile ($S_Max)   Tablet ($M_Min and $M_Max)  Small Desktop ($L_Min and $L_Max)  Large Desktop ($XL_Min - This is the standard for all styles)
*/

/* Large Desktop (switch between simple and mobile nav) */
@media only screen and (min-width: $XL_Min) {

    #header {
        .topHeader {
        }

        .header {
            
        }
    }
}

/* Small Desktop (switch between simple and mobile nav) */
@media only screen and (max-width: $L_Max) {
    h1 {
        font-size: 4.8331rem;
    }
    
    .hamburger {
        display: block !important;
    }

    #header {
        .mainNavWrapper {
            display: none !important;
        }

        .topHeader {
        }
    }

    .mobileNavTree {
        .expertises {
            .k-content {
                h4 {
                    display: none;
                }

                .megaExpertise li{
                    padding: .5rem 1rem;
                    border-bottom: solid 1px $secondaryColourLight;

                    a {
                        color: #fff;
                    }

                    &:hover {
                        background: $primaryColour;
                        text-decoration: none;
                    }
                }
            }
        }
    }

    .homePage .insightsWrapper {
        .splide {
            .slide {
                width: 30% !important;
                &.is-active {
                    width: 40% !important;
                }
            }
        }
    }


    .detailView.teammember {
        .bioTopContent {
            .bioPhotoWrapper {
                .imgWrapper {
                    img {
                        aspect-ratio: 6/5;
                    }
                }
            }
        }
    }
}


/* Tablet */
@media only screen and (max-width: $M_Max) {
    #header {
        .topHeader {
            .logo {
                margin-top: 0.2rem;
            }

            .topHeaderGridRight {
                justify-content: flex-start;

                .social {
                    margin: 1.5rem 4rem 0 0;
                }
            }
        }
    }
    button.hamburger {
        right: $tabletPadding;
    }
    .homeSlideshow {
        ul.splide__pagination {
            top: inherit;
            left: inherit;
            right: inherit;
            bottom: 2rem;
            transform: none;
            display: flex;
            flex-direction: row;
            width: 100%;
            align-items: flex-end;
            max-width: 100%;

            li {
                .splide__pagination__page {
                    width: 0.5rem;
                    height: 0.5rem;
                    margin: 0.5rem;
                    background-color: $greyLight;

                    &.is-active {
                        transform: scale(1);
                        background-color: #fff;
                    }
                }
            }
        }

        .splide__slide {


            .innerContent {

                h1 {
                    text-align: center;
                    font-size: clamp(2rem, 2.076rem + 2.958vw, 5.625rem);



                    em {
                        /*&.protect {
                            span:first-child {
                                padding: 0 19px;
                                margin-right: -9px;
                            }
                        }*/
                    }
                }
            }
        }
    }

    .homePage .insightsWrapper {
        .splide {
            .slide {
                width: 50% !important;

                &.is-active {
                    width: 50% !important;
                }
            }
        }
    }
    footer {
        .mainFooter {
            .mainFooterRight {
                .socialLinks {
                    margin-top:1rem;
                }
            }
        }
    }

    .filteredContent .filteredContentGrid {
        grid-template-columns: 1fr 1fr;
    }

    .topperSection:has(.topperImage) {
        overflow: hidden;
        min-height: 350px;
    }

    .detailView.teammember {
        .bioTopContent {
            .bioPhotoWrapper {
                .imgWrapper {
                    img {
                        aspect-ratio: 1;
                    }
                }
            }

            .bioContact {
                padding-left: $tabletPadding;
                left: 0;
                width: 50%;

                & * {
                    font-size: .9rem;
                }

                .nameDesignationsPronunciation h1 {
                    font-size: 2.4em;
                }

                h3 {
                    font-size: 1.2rem;
                }
            }
        }
    }
    .hz-lazy-load-container  {
        display:none;
    }
    .photoGrid {
        .cmsContent {
            padding:1rem 0;
        }
        img {padding-bottom:1rem;}
    }
}

/* Mobile Only */
@media only screen and (max-width : $S_Max) {
    #header {
        .topHeader {
            .logo {
                margin-top: 0.2rem;

                img {
                    width: 12rem;
                }
            }

            .topHeaderGridRight {
                justify-content: flex-start;

                .social {
                    margin: 1.5rem 4rem 0 0;
                }
            }
        }
    }
    .homePage {
        .topper { margin:0 1rem;}
        .homeSlideshow {
            .splide__slide {
               /* min-height:44rem;
                padding: 0 0 1rem;*/
            }
        }

        .findASearch {
            .k-autocomplete input[type=text] {
                font-size: 1rem;
            }
        }

        .insightsWrapper {
            .splide {
                .slide {
                    width: 100% !important;

                    &.is-active {
                        width: 100% !important;

                        &:after {
                            content: none;
                        }
                    }
                }
            }
        }
    }
    .jumpMenuWrapper {
        height: 0;
        opacity: 0;
       .topSection {
           display:none;
       }
    }
        footer {
            .mainFooter {
                p, li{
                    font-size:1rem;
                }
                .mainFooterLeft {
                    align-items: center;
                }

                .mainFooterRight {
                    align-items: center;
                }

                .footerSitemap {
                    align-items: center;

                    p {
                        text-align: center;
                        margin-bottom: .5rem;
                    }
                }

                .footerSocials {
                    align-items: center;
                    padding-top: 2rem;

                    img {
                        width: 200px;
                    }
                }

                nav.topLevelSimple ul {
                    justify-content: center;
                    flex-wrap: wrap;
                    gap: 0.5rem;
                }
            }
        }

        .filteredContent .filteredContentGrid {
            grid-template-columns: 1fr;
            gap: 2rem;

            .filtersContainer {
                h3 {
                    display: none;
                }

            }
        }

        .tabstrip.k-widget.k-tabstrip .k-tabstrip-items {
            flex-direction: column;

            .k-item {
                padding: .5rem 0;
                border-bottom: solid 1px lighten($primaryColour, 10%);

                &.k-first {
                    border-top: solid 1px lighten($primaryColour, 10%);
                }

                .k-link {
                    padding-left: 0;
                    border: none;
                }
            }
        }

        .darkGreyWrapper {
            .tabstrip.k-widget.k-tabstrip .k-tabstrip-items {
                .k-item {
                    padding: 0;
                    border-bottom: none;

                    &.k-first {
                        border-top: none;
                    }

                    .k-link {
                        padding-left: 1; //hack
                        border: none;
                    }
                }
            }
        }

        .cardView.testimonial blockquote {
            padding: 0.5rem 0;

            p {
                font-size: .9rem;
            }
        }

        .cardView.insight {
            padding-right:0;
        }

    .expertisesWrapper.treeViewWrapper {


        .k-treeview {


            .k-item {
                .k-link, .k-in {
                    font-size: 1.8rem;                
                }
            }
        }
    }

}
/* XS Mobile Only */
@media only screen and (max-width : $S_Mid) {
    blockquote {
        padding: 2rem 2rem 4rem;
    }   
    .searchResultsWrapper {
        display:block;
    }
    .social .searchExpand {
        display:none;
    }
    #header .topHeader .topHeaderGridRight .social {
        margin: 1.25rem 3rem 0 0;
        display: block;
    }
    .topperSection h1 {
        font-size: 3rem;
    }
    h2 {
        font-size: 2.2rem;
    }
    button.hamburger {
        right: $xsPadding;
    }

    .homeSlideshow .splide__slide {
        padding: 9rem 0 13rem;
    }

    .homePage .careersWrapper p {
        max-width: inherit;
        .homeSlideshow .splide__slide {

        }
    }

    .topperSection .topperContent .topperTagLine {
        width:100%;
    }


        .servicesWrapper {
            .splide {
                .itemContent {
                    .hiddenContent {
                        p {
                            font-size: .9rem;
                        }
                    }

                    .button {
                        font-size: .8rem;
                    }
                }
            }
        }

        .detailView.teammember {
            .breadCrumb {
                display: none;
            }

            .mainContent {
                margin-top: 1.2rem;
            }

            .bioTopContent {
                .bioPhotoWrapper {
                    flex-direction: column-reverse;

                    .imgWrapper {
                        width: 100%;

                        img {
                            object-position: inherit;
                            mask-image: none;
                        }
                    }
                }

                .bioContact {
                    position: inherit;
                    padding: 1rem $xsPadding;
                    left: 0;
                    width: 100%;

                    .nameDesignationsPronunciation h1 {
                        font-size: 2em;
                    }
                }
            }
        }


        .splide.wrapper .sliderFooter {
            flex-direction: column-reverse;

            .splide__arrows {
                align-self: flex-start;
                margin-top: 1rem;

                .splide__arrow {
                    margin: 0 .5rem 0 0;
                }
            }

            .viewAllMoreWrapper {
                text-align: left;
            }
        }

        .searchResultsWrapper {
            grid-template-columns: 1fr;
            gap: 0;
        }

        .customButtonWrapper {
            margin-top: 0;
        }

        .contactWrapper {
            .borderRightHighlight {
                border: none;
            }

            .plxl {
                padding: 0 !important;
            }

            .pvl {
                padding-top: 0 !important;
            }

            .innerLogo {
                max-width: 300px;
            }
        }
    }

@media only screen and (max-width : $XS_Max) {
    #header {
        .topHeader {
            .topHeaderGridRight {
                .social {
                    .hideOnSmall {
                        display: none !important;
                    }
                }
            }
        }
    }

    .homeSlideshow {    
        .splide__slide {
            .innerContent {
                h1 {
                    font-size: 2.4rem;
                }
            }
        }
    }

    .detailView {
        h2:before {
            bottom: 0;
            position: absolute;
            left: 50%;
            transform: translate(-50%);
        }
    }
}
