﻿// ### Main Colour Palettes
$primaryColour: #001489;//#001378; // Dark Blue
$secondaryColour: #013cac; // lighter blue
$tertiaryColour: #00d6e6; // Teal;
$highlightColour: $tertiaryColour;
$bodyFontColour: black; // Main Copy

// ### Main Colour Variations
$primaryColourLight: lighten($primaryColour, 10%);
$primaryColourDark: darken($primaryColour, 10%);
$secondaryColourXLight: #E5F1F3; // used for footer subscribe section on insights pages
$secondaryColourLight: lighten($secondaryColour, 10%);
$secondaryColourDark: darken( $secondaryColour, 10% );
$secondaryColourXDark: darken($secondaryColour, 20%);
$secondaryColourTransparent: rgba($secondaryColour, .1) ;
$tertiaryColourDark: darken($tertiaryColour, 10%);
$tertiaryColourLight: lighten($tertiaryColour, 10%);

// ### AODA Colour Palette
$selectionColor: $tertiaryColour;
$selectionColourDark: #1057AD;
$focusColour: $tertiaryColour;

// ### Supporting Color Palettes
$grey: #686D78; // client colour - input field border colour
$greyDark: #555555; // Client Colour - dark grey for headings
$greyXDark: #222222;
$greyLight: #A3A3A3; // client colour - slider dots
$greyXLight: #E2E2E2;
$greyXXLight: #F1F1F1; // Client Colour - light grey used in footer
$greyXXXLight: #F7F7F7;
$offWhite: #F6F6F6;
$offBlack: #0D0D0D;

/* ### Bullets */
$bulletColour: $secondaryColour;

// ### Main Fonts Styles
$primaryFont: nimbus-sans, sans-serif; // body font
$secondaryFont: Financier-Display-Medium, serif;
// headings etc Financier-Display-Medium; //Financier-Display-Bold; //Financier-Display; //Feijoa;//Feijoa-Bold;//

// DO NOT add units to the line-height.
$lineHeight: 1.3; //
$lineHeightHeadings: 1.1; // line-height used for heading/primary font

// Font Weights
$fontWeightThin: 100;
$fontWeightLight: 300;
$fontWeightNormal: 400;
$fontWeightMedium: 500;
$fontWeightSemiBold: 600;
$fontWeightBold: 700;
$fontWeightHeavy: 900;

// ### BreakPoints (Breakpoints above $S_Max matches Semantic breakpoints)
$XXS_Max: 320px; /* XXS Mobile (min width) */
$XS_Min: 321px; /* XS Mobile (min width) */
$XS_Max: 500px; /* XS Mobile (max width) */
$S_Min: 501px; /* Mobile (min width) */
$S_Mid: 650px; /* Mobile (max width) */
$S_Max: 767px; /* Mobile (max width) */
$M_Min: 768px; /* Tablets (min width) */
$M_Max: 1024px; /* Tablets (max width) */
$L_Min: 1025px; /* Small Desktop  (min width) */
$L_Max: 1299px; /* Small Desktop (max width) */
$XL_Min: 1300px; /* Large Desktop (min width) */
$XL_Max: 1599px; /* Large Desktop (max width) */
$XXL_Min: 1600px; /* Extra-Large Desktop (min width) */
$XXL_Max: 1919px; /* Extra-Large Desktop (max width) */
$HD_Min: 1920px; /* High Definition (HD) - THIS IS THE LEVEL OF OUR DEFAULT STYLES */

/* Containers - PLEASE USE REMS */
$container: 70rem; // standard container
$container_narrow: 40rem;
$container_wide: 80rem; // arbitrary container width used throughout the designs (home page overview, main content column, filters box, etc)

// ### Card Grid Properties
$cardWidth: 16rem;
$cardGap: 1.5rem 1.5rem; // row then column

/* Container Padding */
$fullWidthPadding: 7rem;
$largeDesktopPadding: 4rem;
$desktopPadding: 3rem;
$tabletPadding: 2rem;
$mobilePadding: 1.4rem;
$xsPadding: 1.25rem;

/* Whitespace */
$whitespace-xs: 1rem / 4;
$whitespace-s: 1rem / 2;
$whitespace-m: 1rem;
$whitespace-l: 2rem;
$whitespace-xl: 3rem;
$whitespace-xxl: 5rem;
$whitespace-xxxl: 7rem;
$whitespace-xxxxl: 9rem;

/* Base unit used for spacing gutters */
$gutterWidth: 2rem;

// For Mobile Nav Only
$mobileNavWidthTablet: 500px;
$mobileNavWidthPhoneLg: 280px;
$mobileNavWidthPhone: 260px;

/* Buttons */
$buttonBackgroundColour: $secondaryColour;
$buttonBorderColour: #fff;
$buttonBorderWidth: 1px;
$buttonBorderStyle: solid;
$buttonBorderRadius: 0;
$buttonTextColour: $secondaryColour;

// Hamburger menu button
$hamburgerWidth: 45px;
$hamburgerHeight: 45px;

// Loading
$loadingBlur: 4px;
$loadingFallbackTimout: 10s;

// Header Heights
$topHeaderHeight: 60px;
$headerHeight: 122px;
$totalHeaderHeight: 182px; //Sum of the two
$mobileHeaderHeight: 200px;

// ### Misc Variables
$insideTopperHeight: 240px;
$border: 1px solid $greyXLight;
$borderRadius: 5px;
$mobilePadding: 25px;
$transition: all 0.2s ease, font-size 0s linear, padding 0s linear, margin 0s linear, outline 0s linear;
$transitionQuint: all 0.2s cubic-bezier(0.86, 0, 0.07, 1), font-size 0s linear, padding 0s linear, margin 0s linear; // easeInOutQuint - https://easings.net/
$transitionMenuItem: all 0.2s cubic-bezier(0.86, 0, 0.07, 1), font-size 0s linear, margin 0s linear;
$textShadow: 2px 4px 3px rgba(0,0,0,0.3);
$boxShadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
$outline: 2px solid $highlightColour;
$outline_offset: 0;//5px;
$letterSpacing: 0.15em;
$letterSpacingMid: 0.25em;
$letterSpacingSideTitle: 0.35em;

// ### Image Ratios (Used for PhotoGrid)
$ratio2x3: 150%;
$ratio3x4: 133.33%;
$ratio16x9: 56.25%; //1920 x 1080 (3840 x 2160)
$ratio3x2: 66.66%; // 1920 x 1280 (3840 x 2560)
$ratio4x3: 75%;
$ratio1x1: 100%;
$aspectRatio: 1.75/1;

// ### Image Border (Used for PhotoGrid)
$imageBorderSize: 9px; // when doubled = 18px
$imageBorderSizeThirds: 6px; // Example: 18 / 3 = 6
$imageBorderColour: #fff;

// ### Responsive Tables
$flexTableBorderWidth: 2px;
$flexTableFixed2ColWidth: 50%;
$flexTableFixed3ColWidth: 33.33%;
$flexTableFixed4ColWidth: 25%;
$flexTableFixed5ColWidth: 20%;
$flexTableFixed6ColWidth: 16.6%;
