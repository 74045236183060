﻿/* ----------------------------------------------
 * Copied from animasta.net
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}



@keyframes header-slide-in {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0%);
    }
}