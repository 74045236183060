﻿@import '_variables';
@import '_mixins.scss';

@media print {

    body {
        background: none !important;
    }

    .noPrint {
        display: none;
    }

    .printOnly {
        display: block;
        height: auto;
        width: auto;
    }

    // Remove the hyperlink text that chrome / bootstrap etc insert
    a[href]:after {
        content: none !important;
    }

    /* Hide these elements */
    .topHeader {
        display: none !important;
    }

    .mobile,
    .topHeader,
    header nav,
    header .search,
    footer,
    video, audio, object, embed, .jumpMenuWrapper, .printAndShare {
        display: none !important;
        height: 0 !important;
    }

    header .header.stuck {
        position: relative !important;
    }

    img {
        max-width: 100%;
    }

    @page {
        margin: 0.5cm;
    }

    body {
        line-height: $lineHeight;
        background-color: #fff; //$greyXXLight;
    }

    h1 {
        
    }

    h2 {
    }

    h3 {
    }

    .topperTitle {
        h1 {
            color: #fff !important;
        }
    }

    p {
        a {
            color: black;
            word-wrap: break-word;

            &:after {
                content: " " attr(href) ")";
            }

            &[href^="#"]:after {
                display: none;
            }

            &[href^="http://"]:after, a[href^="https://"]:after {
                content: " (" attr(href) ")";
            }
        }
    }

    .filteredContent {
        .filtersContainer, #loadingIndicator, #loadMoreContainer {
            display: none !important;
        }
    }
}


/* ############## PRINTABLE PDF STYLES ############### */
.printWrapper {
    background-color: #fff; //$greyXXLight;
    a {
        color: $primaryColour;
    }

    .cmsContent {
        color: $bodyFontColour;

        a {
            color: $primaryColour;
        }

        ul {
            margin: 0;

            li {
                margin: 0 0 0.25rem 0;
                padding: 0.25rem 0 0 1.25rem;

                &:before {
                    height: 1rem;
                    background-image: url(/CFImages/Icons/bullet-square-solid.svg);
                }
            }
        }
    }

    .cmsContent.highlightFirstElement > :first-child {
        margin: 0 0 1rem;
    }

    table {
        width: auto;
        height: auto;
        margin: 0;
        padding: 0;
        border: none;
        border-collapse: inherit;
        border-spacing: 0;
        border-color: inherit;
        vertical-align: inherit;
        text-align: left;
        font-weight: inherit;

        tr {
            td {
                vertical-align: top;
            }
        }
    }

    p, li {
        font-size: 14px;
        word-break: break-word;
    }

    .cmsContent {
        p, li {
            font-size: 14px;
            line-height: 1.1rem;
        }
    }

    h1, h2, h3, h4, h5 {
        text-align: left;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h {

        &:first-child {
            margin-top: revert;
        }
    }

    h1 {
        font-size: 1.5rem !important; //using important to minimize number of rules that need to be overridden
    }

    h2 {
        font-size: 1.4rem !important; //using important to minimize number of rules that need to be overridden
        margin: 0;
        padding-bottom: 0;
        break-inside: avoid;
        margin: 1.5rem 0 2.4rem;
    }

    h2:after { // basically give the header an extra element that is large enough that it will break if needed.
        //NOTE: need break-inside: avoid on the parent element
        content: "";
        display: block;
        height: 120px;
        position: relative;
        margin-bottom: -120px;
    }

    h2.smallMargin:after { // basically give the header an extra element that is large enough that it will break if needed.
        //NOTE: need break-inside: avoid on the parent element
        content: "";
        display: block;
        height: 180px;
        margin-bottom: -180px;
    }

    h2.mediumMargin:after { // basically give the header an extra element that is large enough that it will break if needed.
        //NOTE: need break-inside: avoid on the parent element
        content: "";
        display: block;
        height: 300px;
        margin-bottom: -300px;
    }

    h2.superMargin:after { // basically give the header an extra element that is large enough that it will break if needed.
        //NOTE: need break-inside: avoid on the parent element
        content: "";
        display: block;
        height: 500px;
        margin-bottom: -500px;
    }

    h4.superMargin {
        font-size: 1.2rem !important; //using important to minimize number of rules that need to be overridden
        break-inside: avoid;
    }

    h4::after { // basically give the header an extra element that is large enough that it will break if needed.
        //NOTE: need break-inside: avoid on the parent element
        content: "";
        display: block;
        height: 400px;
        margin-bottom: -400px;
    }

    * /*/

    .avoidPageBreak {
        page-break-inside: avoid;
    }
    /*.h3, .cmsContent.highlightFirstElement > :first-child {
        font-size: 1.1rem !important;
    }*/
    .container {
        width: 100%;
        padding: 0;
        margin: 0;
        max-width: 100% !important;
    }

    .paddedWithMargin {
        padding: .5rem 2rem 2rem;
        margin: 1.5rem 0;
    }

    .baseFont {
        font-family: $primaryFont;
    }

    .logoWrapper {
        padding: 1.5rem;

        img {
            max-width: 150px;
        }
    }

    .viewAllMoreWrapper {
        display: none;
    }
    /* Shared List views and cards */
    .listView {
        display: block;
        gap: initial;
        margin-bottom: 0;

        &.expertiseandsectors.expertises {
            padding-bottom: 1rem;

            .cardView.expertise {
                justify-content: flex-start;
                padding: .5rem 0;
                font-family: $secondaryFont;


                h3 {
                    font-size: 1.1rem;
                    text-align: left;
                    margin-top: 0;

                    a {
                        color: $primaryColour;
                        padding: .5rem 0;
                    }
                }

                svg {
                    display: none !important;
                }

                .hoverContent {
                    display: none !important;
                }
            }
        }

        &.rankings {
            display: block;

            .cardView.ranking.print {
                padding: 0;

                &:before {
                    content: none;
                    padding: 0;
                }
            }
        }

        &.insights {
            .cardView.insight.withSourceName {
                width: 100%;
                margin: 0;
                padding: .5rem 0 0.5rem 0;
                border-bottom: 1px solid $tertiaryColour;


                h3 {
                    margin: 0 0 .3rem 0;
                    font-size: 1.2rem;
                }

                span.sourceName {
                    display: block;
                    font-size: 0.9rem;
                    font-family: $primaryFont;
                    color: $bodyFontColour;
                }
            }
        }
    }

    .mainContent {
        padding: 0 2rem 0 0rem;
    }
    /* Two Column Table */
    table.mainGrid {
        tr td {
            vertical-align: top;
            border: none;
            border-spacing: 0px;
        }

        .secondaryContent {
            //margin-top: 1rem;
            padding: 0.5rem 1.5rem 0.5rem 1.5rem;

            h2 {
                font-size: 1.2rem;
                margin-bottom: 1.5rem;
            }

            ul li {
                font-size: 1rem !important;
            }
        }
    }
    /* bottom table (for resource etc) */
    table.fullGrid {
        width: 100%;
    }
    /* Team Bio Specific Styles*/
    .detailView.teammember {

        .bioTopContent {
            margin-bottom: 1rem;
            /* top table (for contact and image) */
            table.topGrid {
                background-color: $greyXXLight;
                border-spacing: 0px;

                .bioContact {
                    position: relative;
                    font-size: 1.1rem;
                    height: 100%;
                    width: 100%;

                    .nameDesignationsPronunciation {
                        h1 {
                            font-size: 1.6rem;
                            margin-bottom: 0;
                            color: #252B6F;
                        }
                    }

                    h3.position {
                        font-size: 1rem !important;
                        font-family: $primaryFont;
                    }

                    ul.contactInfo {
                        margin: 0;

                        li {
                            font-size: 1rem !important;
                            line-height: 1.2;
                        }
                    }
                }

                .professionalCorporation em {
                    font-size: .6rem !important;
                    margin-top: .5rem;
                }

                .bioPhotoWrapper .imgWrapper {
                    width: 100%;

                    img {
                        object-position: 40%;
                        //background: linear-gradient(to right, transparent 0%, $greyXXLight 12%);
                        aspect-ratio: 7/5;
                    }
                }
            }
        }
    }

    .detailView.insight {
        .secondaryContent {
            .teammembersWrapper {
                margin-bottom: 2rem;

                .teammembers {
                    display: block;
                }
            }
        }
    }
    /* ##### FOR EXPERTISE PAGES ### */
    table.topGrid.photoBackground {
        margin-bottom: 2rem;
        display: block;

        td {
            display: inline;
            padding: 0;
        }

        .topperImage {
            position: relative;
            height: 200px;
            overflow: hidden;
            @include gradientOverlay;
        }

        h1 {
            color: $primaryColour;
            position: absolute !important;
            z-index: 3 !important;
            top: 50%;
            left: 1rem;
            transform: translatey(-50%);
            color: #fff;
            font-size: 2rem !important;
            margin: 0;

            .dates {
                display: block;
                font-size: 1rem;
            }
        }
    }
    /* ##### FOR KEYCONTACT and RELATED TEAM #### */
    .teammembersWrapper {
        .teammembers {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 2rem;
            /*li {
                break-inside: avoid;
            }*/
        }

        .cardView.teammember {
            display: block;
            //width: 40%;
            /*margin: 5px 3px;*/
            padding-bottom: .75rem;
            break-inside: avoid;

            .itemContent {
                padding-left: 0;
                display: block;
                margin-top: .5rem;

                h3 {
                    margin: 0;
                    color: red;
                    font-size: 1.1rem;
                }

                .position {
                    color: $greyXDark;
                    margin-bottom: 0;
                    font-size: .85rem;
                }

                a {
                    color: $primaryColour;
                }

                .contactInfo {
                    li {
                        font-size: 90%;
                    }

                    .prefixLabel {
                        color: $greyXDark;
                        font-weight: 500;
                    }
                }
            }
        }
    }

    .expertisesubgroups {
        .teammembersWrapper {
            .teammembers {
                grid-template-columns: 1fr;

                .teammemberGrid {
                    .teamLeft {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        gap: 2rem;
                        margin-bottom: 2rem;

                        .socialLinks, .audioPlayerWrapper {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .greyWrapper .cardView.teammember {
        border-bottom: none;

        .contactInfo {
            margin-bottom: 0;

            li {
                font-size: .85rem !important;
            }
        }
    }
}
